var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "flat": _vm.flat,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 600 : ''
    }
  }, [_c('v-card-title', {
    staticClass: "pt-1"
  }, [_c('div', [_c('div', {
    staticClass: "bold-text font-size-h4"
  }, [_vm._v("Send Feedback")]), _c('div', {
    staticClass: "font-size-h4"
  }, [_vm._v(" Help us improve our product, any feedback is welcome. ")])])]), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    staticClass: "mx-0 px-0"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|max:600',
      expression: "'required|max:600'"
    }],
    attrs: {
      "placeholder": "Let us know what could be we doing better!",
      "filled": "",
      "autofocus": "",
      "color": "primary",
      "counter": "600"
    },
    model: {
      value: _vm.feedback.message,
      callback: function callback($$v) {
        _vm.$set(_vm.feedback, "message", $$v);
      },
      expression: "feedback.message"
    }
  }, 'v-textarea', _vm.veeValidate('Message', ''), false))], 1), _c('v-col', {
    staticClass: "py-0",
    class: {
      'text-center': _vm.$vuetify.breakpoint.smAndDown,
      'mx-3': _vm.$vuetify.breakpoint.mdAndUp,
      'pb-6': _vm.$vuetify.breakpoint.smAndDown,
      'pl-n10': _vm.$vuetify.breakpoint.smAndDown
    },
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.emojiRating, function (item, index) {
    return _c('span', {
      key: index
    }, [_c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var hover = _ref.hover;
          return [_c('v-btn', {
            attrs: {
              "small": "",
              "icon": ""
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.setFeedbackRate(item.value);
              }
            }
          }, [_c('v-img', {
            attrs: {
              "src": item.icon,
              "color": "warning",
              "contain": "",
              "width": "40",
              "height": _vm.feedback.rate === item.value || hover ? '30' : '25'
            }
          })], 1)];
        }
      }], null, true)
    })], 1);
  }), 0)], 1)], 1), _c('v-row', {
    staticClass: "px-0 mx-0"
  }, [_c('v-col', {
    staticClass: "pr-6 text-center",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize",
    class: {
      'mr-2': _vm.$vuetify.breakpoint.mdAndUp
    },
    attrs: {
      "depressed": "",
      "loading": _vm.busy,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.sendFeedback.apply(null, arguments);
      }
    }
  }, [_vm._v("Send Feedback")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-col', {
    staticClass: "mx-auto py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-col', {
    staticClass: "py-0 text-center font-size-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Reach anytime and everywhere ")]), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    class: _vm.$vuetify.breakpoint.smAndDown ? 'text-center' : '',
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none mx-auto",
    attrs: {
      "color": "primary",
      "depressed": "",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.openSchedule
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-phone')
    }
  }), _vm._v(" Schedule a call ")], 1)], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, _vm._l(_vm.socialLinks, function (item, index) {
    return _c('v-tooltip', {
      key: index,
      attrs: {
        "bottom": "",
        "color": "primary"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on;
          return [_c('v-btn', _vm._g({
            staticClass: "ma-1",
            attrs: {
              "depressed": "",
              "icon": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.openSocialLinks(item.link);
              }
            }
          }, on), [_c('v-icon', {
            domProps: {
              "textContent": _vm._s(item.icon)
            }
          })], 1)];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(item.title))])]);
  }), 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }