<template>
  <div class="pb-14">
    <div>
      <div>
        <v-row wrap>
          <v-col cols="12">
            <v-text-field
              id="updateTeamName"
              v-model="update.name"
              v-validate="'required|min:3|max:27|name_field_validation'"
              counter="30"
              filled
              placeholder="Team name"
              v-bind="veeValidate('Name', 'Name')"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div class="mt-4">
        <template>
          <v-textarea
            id="updateTeamDesc"
            v-model="update.description"
            v-validate="'max:600'"
            auto-grow
            class="mt-8"
            counter="600"
            filled
            placeholder="Description about this team  (Optional)"
            v-bind="veeValidate('Description', 'Description')"
          ></v-textarea>
        </template>
      </div>
      <div v-show="!info?.info?.ownership" class="mt-4">
        <template>
          <ownership-autocomplete
            :default-value="ownership"
            @selected="update.info.ownership = $event"
          ></ownership-autocomplete>
        </template>
      </div>
    </div>
    <div class="text-center">
      <v-btn
        id="updateTeamBtn"
        :disabled="!update.name || errors.any()"
        :loading="busy"
        :outlined="$vuetify.theme.dark"
        class="text-transform-none py-7 px-10 my-3"
        color="primary"
        @click="updateTeam"
      >
        <span class="font14"> Save </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import { GET_TEAM_INFO, UPDATE_TEAM } from "@/store/team/manageTeam.module";
import { mapGetters, mapMutations } from "vuex";

import { Validator } from "vee-validate";
import { isEmpty } from "@/core/services/helper.service";
import { CLEAR_STORE } from "@/store/users/auth.module";
import OwnershipAutocomplete from "@/view/components/Common/OwnershipAutocomplete";

Validator.extend("name_field_validation", {
  getMessage: (field) => {
    return `${field} should not include any special characters `;
  },
  // Returns a boolean value
  validate: (value) => {
    const regex = new RegExp("^[0-9A-Za-z(-_.)ds ]+$");
    return regex.test(value);
  },
});

export default {
  components: { OwnershipAutocomplete },
  mixins: [veeValidate],
  data() {
    return {
      update: {
        name: "",
        description: "",
        info: {
          ownership: "",
        },
      },
      busy: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamId: "getTeamId",
      info: "getTeamInfo",
    }),
    ownership() {
      return this.update.info.ownership;
    },
  },
  created() {
    this.getTeamInfo();
  },
  methods: {
    ...mapMutations({
      setTeamName: "setTeamName",
      updateTeamName: "updateTeamName",
    }),
    getTeamInfo() {
      if (isEmpty(this.info)) {
        this.$store
          .dispatch(GET_TEAM_INFO)
          .then(() => {
            if (this.info && this.info.name) {
              this.update = { ...this.info };
            }
          })
          .catch(() => {
            this.$store.dispatch(CLEAR_STORE);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.update = { ...this.info };
      }
    },
    async updateTeam() {
      if (await this.validateAllFields()) {
        if (this.busy) return;
        this.busy = true;
        this.$store
          .dispatch(UPDATE_TEAM, this.update)
          .then((response) => {
            this.setTeamName(this.update.name);
            this.updateTeamName({
              id: this.getTeamId,
              name: this.update.name,
              info: this.update.info,
            });
            this.$emit("close", this.update.name);
            this.notifyUserMessage({
              message: response.message || "Successfully updated",
              timeout: "6000",
            });
            this.busy = false;
            // this.update = {
            //   name: "",
            //   description: "",
            //   info: {
            //     ownership: ''
            //   }
            // };
          })
          .catch((err) => {
            this.busy = false;
            this.notifyErrorMessage(err.message);
          });
      }
    },
  },
};
</script>
