<template>
    <div>
        <div v-if="loading">
            <v-row>
                <v-col v-for="i in 4" :key="i" cols="12">
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <div v-if="whatsNewList && whatsNewList.length">
                <v-card outlined elevation="1" v-for="(item, index) in whatsNewList" :key="index" class="my-4 lightBorder">
                    <what-new-card :detail="item"></what-new-card>
                </v-card>
            </div>
            <div v-else>
                <v-col class="no-data-found">
                    <inline-svg class="mt-6 svg-icon empty_icon" src="/media/placeholders/error.svg" />
                    <p class="mt-4 empty-title bold-text text-dark text-center">
                        No new updates found.
                    </p>
                    <p class="mt-4 mb-5 empty-sub-title font-weight-normal text-dark text-center">
                        <!--        Upgrade your Team now-->
                    </p>
                </v-col>
            </div>
        </div>
        <infinate-loading-base :endpoint="endpoint" :reset="true" :distance="200" @update-loading="loading = $event"
            @setInfiniteResponse="setInfiniteResponse" @resetInfiniteResponse="resetInfiniteResponse"></infinate-loading-base>
    </div>
</template>

<script>
import {
    FETCH_WHATS_NEW_LIST,
    CLEAR_WHATS_NEW_LIST,
} from "@/store/users/whatsNew.module.js";
import { mapGetters } from "vuex";
import InfinateLoadingBase from "@/view/components/Common/InfinateLoadingBase.vue";
import WhatNewCard from "@/view/components/Common/WhatNewCard";
import { segmentEvents } from "@/consts/segmentEventConst";

export default {
    components: {
        WhatNewCard,
        InfinateLoadingBase,
    },
    data() {
        return {
            endpoint: FETCH_WHATS_NEW_LIST,
            emojiRating: [
                {
                    icon: "/media/emoji/in-love.png",
                    value: 2,
                },
                {
                    icon: "/media/emoji/happy.png",
                    value: 1,
                },
                {
                    icon: "/media/emoji/sad.png",
                    value: 3,
                },
            ],
            whatsNewData: [],
            loading: false,
            busy: false,
        };
    },
    computed: {
        ...mapGetters({
            whatsNewList: "getWhatsNewList",
        }),
    },
    created() {
        this.logEvent(segmentEvents.WHAT_NEW);
    },
    beforeDestroy() {
        this.$store.commit(CLEAR_WHATS_NEW_LIST);
    },
    methods: {
        setInfiniteResponse() {
            this.$emit("updateId");
        },
        resetInfiniteResponse() {
            this.$store.commit(CLEAR_WHATS_NEW_LIST);
        },
    },
};
</script>
