<template>
  <div>
    <v-navigation-drawer
      v-if="showAppMenu && !getInfoErrorMessage"
      id="navigationDrawer"
      v-model="drawer"
      :class="$vuetify.theme.dark ? 'navigationDark' : 'navigationFont'"
      :expand-on-hover="$vuetify.breakpoint.mdAndUp"
      :mini-variant="$vuetify.breakpoint.mdAndUp"
      app
      clipped
      height="100%"
    >
      <v-list
        v-show="!showAppMenu"
        :class="$vuetify.breakpoint.mdAndUp ? 'mt-15 pt-5' : ''"
        dense
        nav
      >
        <v-list>
          <v-list-item
            id="teamSwitchBtn"
            class="pb-0 pt-0"
            @click="teamActionModal = true"
          >
            <v-list-item-icon>
              <v-icon color="darkGrey" small>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <div id="teamName" class="bodyFont darkGrey--text">
                {{ getTeamName }}
              </div>
            </v-list-item-title>
            <v-list-item-action>
              <v-icon v-text="'mdi-chevron-right'"></v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <template v-for="item in standardMenuItems">
          <v-list-item
            v-if="!item.children"
            id="item.title"
            :key="item.title"
            v-model="item.active"
            :class="
              !helpers.isEmpty(getBroadCastMessage) && item.title == 'Dashboard'
                ? 'mt-7'
                : ''
            "
            :to="item.to"
            link
          >
            <v-list-item-icon>
              <v-icon color="darkGrey" small>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <div class="darkGrey--text font14">{{ item.title }}</div>
            </v-list-item-title>
          </v-list-item>
          <v-list-group
            v-if="item.children && item.show"
            id="teamPageList"
            :key="item.title"
            v-model="item.active"
            :class="$vuetify.theme.dark ? 'navigationDark' : 'navigationFont'"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item id="teamPageList" class="px-0">
                <v-list-item-icon>
                  <v-icon color="darkGrey" small>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <span class="darkGrey--text py-2 bodyFont">{{
                    item.title
                  }}</span>
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-for="child in item.children">
              <v-list-item
                v-if="child.show"
                :id="child.title"
                :key="child.title"
                :to="child.to"
                class="px-10"
                link
              >
                <v-list-item-title>
                  <div class="darkGrey--text font14 py-2">
                    {{ child.title }}
                  </div>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
        </template>

        <v-list-item
          v-if="$vuetify.breakpoint.smAndDown"
          link
          to="/notifications"
        >
          <v-badge
            v-if="totalNotifications"
            :content="
              `${totalNotifications}`.includes('-') ? '0' : totalNotifications
            "
            color="success"
            style="z-index: 1"
          >
            <v-icon class="mr-9" color="darkGrey" small>mdi-bell</v-icon>
            <span class="bodyFont">Notifications</span>
          </v-badge>
          <template v-else>
            <v-list-item-icon>
              <v-icon color="darkGrey" small>mdi-bell</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <div class="bodyFont">Notifications</div>
            </v-list-item-title>
          </template>
        </v-list-item>
      </v-list>

      <v-list
        v-if="showAppMenu && !getInfoErrorMessage"
        :class="$vuetify.breakpoint.mdAndUp ? 'mt-15' : ''"
        dense
        nav
      >
        <v-list-item
          :class="!helpers.isEmpty(getBroadCastMessage) ? 'mt-8' : ''"
          :to="pageRoutes.app.list"
          link
        >
          <v-list-item-icon>
            <v-icon color="darkGrey">mdi-chevron-left</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <div class="py-2 bodyFont darkGrey--text">All apps</div>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          class="px-0"
          @click="
            $router.push({
              name: 'app-settings',
              params: { app_id: $route.params.app_id },
            })
          "
        >
          <v-list-item-avatar>
            <v-img
              :src="
                appInfo?.image ||
                `/media/defaults/${
                  $vuetify.theme.dark ? 'app_default_light' : 'app_default'
                }.svg`
              "
              contain
              height="30"
              width="30"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-title>
            <span id="appInfoName" class="bodyFont ml-3 mt-2 darkGrey--text">{{
              appInfo?.name
            }}</span>
          </v-list-item-title>
        </v-list-item>
        <template v-for="(item, cindex) in menuList">
          <v-list-item
            v-if="item.show && !item.children"
            :id="item.title"
            :key="cindex"
            :class="$vuetify.theme.dark ? 'navigationDark' : 'navigationFont'"
            :to="`/app/${$route.params.app_id}/${item.link}`"
            active-class="act"
          >
            <v-list-item-icon>
              <v-icon class="darkGrey--text" small>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <div class="py-2 bodyFont darkGrey--text 1">
                {{ item.title }}
                <v-chip
                  v-if="item.badge"
                  :color="item?.badge?.color"
                  class="py-2"
                  label
                  outlined
                  x-small
                >
                  {{ item?.badge?.label }}
                </v-chip>
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-group
            v-if="item.children && item.show"
            id="teamPageList"
            :key="item.title"
            v-model="item.active"
            :class="$vuetify.theme.dark ? 'navigationDark' : 'navigationFont'"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item id="teamPageList" class="px-0">
                <v-list-item-icon>
                  <v-icon color="darkGrey" small>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <span class="darkGrey--text py-2 bodyFont">{{
                    item.title
                  }}</span>
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-for="child in item.children">
              <v-list-item
                v-if="child.show"
                :id="child.title"
                :key="child.title"
                :to="`/app/${$route.params.app_id}/${child.link}`"
                class="px-10"
                exact
              >
                <v-list-item-icon>
                  <v-icon color="darkGrey" small>
                    {{ child.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <div class="darkGrey--text font14 py-2">
                    {{ child.title }}
                  </div>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list>

      <template v-slot:append>
        <v-list dense>
          <v-list-item
            v-if="canManageTeam"
            class="darkGrey--text"
            @click="showUpgrade"
          >
            <v-list-item-icon class="pr-0">
              <v-icon color="darkGrey"> mdi-rocket-launch</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span class="darkGrey--text bodyFont">
                {{ getCurrentPlanName }}
              </span>
            </v-list-item-title>
            <v-list-item-action class="pr-8">
              <div class="pt-1">
                <v-chip
                  small
                  v-if="
                    getCurrentPlanName !== 'Pro' &&
                    !(
                      showStartTrialAlert ||
                      showActiveTrialAlert ||
                      showTrialEnded
                    )
                  "
                  color="info"
                >
                  Upgrade
                </v-chip>
                <v-chip
                  v-if="showStartTrialAlert || showTrialEnded"
                  color="error"
                  small
                >
                  Start Trial</v-chip
                >
                <v-chip v-if="showActiveTrialAlert" color="error" small>
                  Trial</v-chip
                >
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-list-item></v-list-item>
          <v-list-item
            v-if="$vuetify.breakpoint.smAndDown"
            @click="openWhatsNewList"
          >
            <v-badge
              v-if="!showWhatsNewBadge"
              color="success"
              dot
              style="z-index: 1"
            >
              <v-icon class="mr-9" color="darkGrey" small
                >mdi-newspaper
              </v-icon>
              <span class="darkGrey--text bodyFont">What's New</span>
            </v-badge>
            <template v-else class="svg-icon svg-icon-xl dark-grey-text">
              <v-list-item-icon>
                <v-icon color="darkGrey" small>mdi-newspaper</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <span class="darkGrey--text bodyFont">What's New</span>
              </v-list-item-title>
            </template>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <Modal
      v-model="openTrialModal"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :width="$vuetify.breakpoint.mdAndUp ? 984 : ''"
      no-py
      @close="openTrialModal = false"
    >
      <template #message>
        <start-trial-modal
          from-menu
          @close="openTrialModal = false"
        ></start-trial-modal>
      </template>
    </Modal>
    <Modal
      v-model="teamActionModal"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      width="800"
    >
      <template #card>
        <v-card v-if="teamActionModal" :id="teamActionModal">
          <v-card-title>
            <div class="subListFont">Team Actions</div>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="(teamActionModal = false), (selectedAction = 1)"
            >
              <v-icon v-text="'mdi-close'"></v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-list dense>
                  <v-list-item
                    v-for="(item, index) in teamActions"
                    :key="index"
                    :class="
                      selectedAction === item.value ? 'primary white--text' : ''
                    "
                    class="py-1"
                    @click="selectedAction = item.value"
                  >
                    <div
                      :class="
                        selectedAction === item.value
                          ? 'primary white--text'
                          : ''
                      "
                      class="pointer bodyFont"
                    >
                      <v-icon
                        :color="selectedAction === item.value ? 'white ' : ''"
                        left
                        small
                      >
                        {{ item.icon }}
                      </v-icon>
                      {{ item.name }}
                    </div>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col>
                <team-list v-if="selectedAction === 1" from-menu></team-list>
                <update-team-info
                  v-if="selectedAction === 3"
                  @close="teamActionModal = false"
                ></update-team-info>
                <create-team
                  v-if="selectedAction === 2"
                  from-switch-team
                  @teamCreated="afterSuccess"
                ></create-team>
                <leave-team-modal
                  v-if="selectedAction === 4"
                  @close="onCancel"
                ></leave-team-modal>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </Modal>

    <Modal
      v-model="showFeedbackForm"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :width="$vuetify.breakpoint.smAndDown ? '' : 600"
      noPy
      @close="closeFeedbackForm"
    >
      <template #message>
        <feedback-form flat @close="showFeedbackForm = false"></feedback-form>
      </template>
    </Modal>

    <SidePanel v-model="openRelease" width="700" @close="closeReleaseModal">
      <create-release
        v-if="openRelease"
        :app-info="appInfo"
        from-create-panel
        @close="closeReleaseModal"
      ></create-release>
    </SidePanel>
  </div>
</template>

<script>
import {
  nonMenuRoutes,
  socialMediaInfo,
  teamActions,
} from "@/view/layout/SideNavigationMenu/const";
import { formatBytes, get, isEmpty } from "@/core/services/helper.service";
import { mapGetters } from "vuex";
import pageRoutes from "@/consts/routes/pageRoutes";
import dayjs from "dayjs";
import StartTrialModal from "@/view/components/Common/StartTrialModal";
import LeaveTeamModal from "@/view/components/Team/LeaveTeamModal.vue";
import UpdateTeamInfo from "@/view/components/Team/UpdateTeamInfo.vue";
import TeamList from "@/view/components/Team/List.vue";
import CreateTeam from "@/view/components/Team/Create.vue";
import FeedbackForm from "@/view/components/Common/FeedbackForm.vue";
import CreateRelease from "@/view/components/Releases/CreateRelease.vue";

export default {
  components: {
    CreateRelease,
    FeedbackForm,
    CreateTeam,
    TeamList,
    UpdateTeamInfo,
    StartTrialModal,
    LeaveTeamModal,
  },
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      helpers: {
        formatBytes,
        get,
        isEmpty,
      },
      teamActionModal: false,
      openTrialModal: false,
      pageRoutes,
      openRelease: false,
      socialMediaInfo,
      showFeedbackForm: false,
      showWhatsNewList: false,
      selectedAction: 1,
      teamActions,
      createTeamModal: false,
      deleteModal: false,
      storeLink: {},
      leaveModal: false,
      appStoreLinkModal: false,
    };
  },
  computed: {
    standardMenuItems() {
      return [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          link: "dashboard",
          to: "/",
        },
        {
          title: "Team",
          show: !!this.canViewTeamMembers,
          icon: "mdi-account-group",
          children: [
            {
              title: "Members",
              icon: "mdi-account-group-outline",
              to: "/team/members",
              show: !!this.canViewTeamMembers,
            },
            {
              title: "Invites",
              icon: "mdi-account-multiple-plus-outline",
              to: "/team/invites",
              show: !!this.canViewInvite,
            },
            {
              title: "Storage",
              icon: "mdi-database",
              show: !!this.canViewStorage,
              to: "/team/storage",
            },
            {
              title: "Integrations",
              icon: "mdi-settings",
              show: !!this.canManageTeam,
              to: "/team/integrations",
            },
            {
              title: "Plan & Usage",
              icon: "mdi-cash",
              show: !!this.canManageTeam,
              to: "/team/usage",
            },
          ],
        },
        { title: "Apps", link: "apps", icon: "mdi-apps", to: "/apps" },
      ];
    },
    showAppMenu() {
      return this.appActive;
    },
    showMenu() {
      return !nonMenuRoutes.includes(this.$route.name);
    },
    totalNotifications() {
      if (this.userNotifications.alerts + this.userNotifications.invites > 99) {
        return "99+";
      } else {
        return this.userNotifications.alerts + this.userNotifications.invites;
      }
    },
    canManageApp() {
      return !!(this.userPermissions?.app && this.userPermissions?.app?.manage);
    },
    menuList() {
      return [
        {
          title: "Dashboard",
          component: "app-dashboard",
          link: "dashboard",
          show: true,
          icon: "mdi-view-dashboard",
          value: 0,
        },
        {
          title: "Chat",
          component: "app-chat",
          tag: "",
          link: "chat",
          show: !!this.canViewChat,
          icon: "mdi-chat-outline",
          value: 1,
        },
        {
          title: "Releases",
          component: "app-releases",
          tag: "",
          link: "releases",
          show: true,
          // badge: {
          //   label: "Updated",
          //   color: "primary",
          // },
          icon: "mdi-package-variant-closed",
          value: 2,
        },
        {
          title: "Sessions",
          show: !!this.canManageApp,
          icon: "mdi-timeline-text-outline",
          link: "sessions",
          badge: {
            label: "BETA",
            color: "warning",
          },
        },
        {
          title: "Integrations",
          component: "app-integrations",
          link: "integration",
          badge: {
            label: "New",
            color: "success",
          },
          show: !!this.canManageApp,
          icon: "mdi-transit-connection",
          value: 6,
        },
        {
          title: "Settings",
          // badge: {
          //   label: "Updated",
          //   color: "primary",
          // },
          component: "app-settings",
          link: "settings",
          show: true,
          icon: "mdi-cog",
          value: 8,
        },
      ];
    },
    showStartTrialAlert() {
      return (
        isEmpty(this.getTeamTrial) &&
        !!this.getTeamId &&
        !this.getIsUserDisabled
      );
    },
    showActiveTrialAlert() {
      return (
        !isEmpty(this.getTeamTrial) && this.getTeamTrial.status === "active"
      );
    },
    showTrialEnded() {
      return (
        !isEmpty(this.getTeamTrial) && this.getTeamTrial.status === "ended"
      );
    },
    showWhatsNewBadge() {
      return this.getWhatsNewLastReadId === this.lastNewIdFromLocalStore;
    },
    ...mapGetters({
      userNotifications: "userNotificationsStats",
      getBroadCastMessage: "getBroadCastMessage",
      appInfo: "getAppInfo",
      getTeamName: "getTeamName",
      getCurrentPlanName: "getCurrentPlanName",
      getTeamTrial: "getTeamTrial",
      getInfoErrorMessage: "getInfoErrorMessage",
      getWhatsNewLastReadId: "getWhatsNewLastReadId",
      appActive: "getAppActivity",
    }),
  },
  methods: {
    closeFeedbackForm() {
      this.setFeedbackModalDetail(false);
      this.showFeedbackForm = false;
    },
    showUpgrade() {
      if (this.getCurrentPlanName === "Pro") {
        this.$router.push({ name: "team-plan" });
      } else {
        this.openTrialModal = true;
      }
    },
    redirectTo(link) {
      this.$router.push({ name: link });
      if (link === "dashboard" && this.getDarkTheme) {
        this.$vuetify.theme.dark = true;
      }
    },
    openAppDownloadModal(platform) {
      const url =
        platform === "android"
          ? "https://play.google.com/store/apps/details?id=testapp.io"
          : "https://apps.apple.com/us/app/testapp-io/id1518972541";
      this.storeLink = {
        platform,
        url,
      };
      this.appStoreLinkModal = true;
    },
    openWhatsNewList() {
      this.showWhatsNewList = true;
    },
    trialDaysLeft() {
      const expiryDate = new Date(
        `${dayjs(this.getTeamTrial.end_at * 1000)}`
      ).getTime();
      const currentDate = new Date().getTime();
      const diffDate = Math.ceil(
        (expiryDate - currentDate) / (1000 * 3600 * 24)
      );
      return diffDate;
    },
    async afterSuccess(plan) {
      this.createTeamModal = false;
      this.teamActionModal = false;
      if (["Pro", "Starter"].includes(plan)) {
        await this.$router.push({ name: "team-plan" });
      } else if (this.$route.params && this.$route.params.app_id) {
        await this.$router.push({ name: "apps" });
      }
      this.showSuccessTeamSwitch = true;
      this.$nextTick(() => {
        window.location.reload();
      });
    },
    onCancel() {
      this.deleteModal = false;
      this.leaveModal = false;
      this.teamActionModal = false;
    },
    isTrialExpiring() {
      const expiryDate = new Date(
        `${dayjs(this.getTeamTrial.end_at * 1000)}`
      ).getTime();
      const currentDate = new Date().getTime();
      const diffDate = Math.ceil(
        (expiryDate - currentDate) / (1000 * 3600 * 24)
      );
      return diffDate <= 3;
    },
    openSocialMediaLink(link) {
      window.open(link, "blank");
    },
    closeReleaseModal() {
      if (this.getTourInfo()) {
        this.startTour();
      }
      this.openRelease = false;
    },
    getTourInfo() {
      let info = JSON.parse(localStorage.getItem("tour"));
      if (info && info.upload_queue === "true") {
        return false;
      } else {
        return true;
      }
    },
    startTour() {
      this.$tours["headerTour"]?.start();
    },
  },
};
</script>

<style lang="scss" scoped></style>
