<template>
  <div>
    <div
      v-if="!isEmpty(getBroadCastMessage)"
      :class="
        !$vuetify.breakpoint.smAndDown
          ? 'infoBanner ' + getBroadCastMessage.settings.color
          : getBroadCastMessage.settings.color
      "
      class="text-center py-2 white--text"
    >
      <span v-html="getBroadCastMessage.message"> </span>
      <span
        v-if="getBroadCastMessage.settings.dismissible"
        class="text-right float-end right top"
      >
        <v-icon
          class="px-3"
          color="offWhite"
          @click="dismissBroadcastMessage"
          v-text="'mdi-close-circle'"
        ></v-icon>
      </span>
    </div>

    <!-- Tour -->
    <v-tour
      :callbacks="myCallbacks"
      :options="tourOptions"
      :steps="steps"
      name="headerTour"
      style="background-color: #ffffff; color: #0067ce"
    ></v-tour>

    <v-app-bar
      :class="
        !isEmpty(getBroadCastMessage) && !$vuetify.breakpoint.smAndDown
          ? 'mt-9'
          : ''
      "
      :color="$vuetify.theme.dark ? '#22272e' : 'white'"
      :fixed="!$vuetify.breakpoint.smAndDown"
      :flat="!$vuetify.theme.dark"
      clipped-right
    >
      <v-btn
        v-if="
          $vuetify.breakpoint.mdAndDown &&
          $route.path !== pageRoutes.welcome &&
          $route.path !== pageRoutes.getStarted
        "
        icon
        large
        class="ml-3"
        @click="drawer = !drawer"
      >
        <v-icon>menu</v-icon>
      </v-btn>
      <v-spacer v-if="$vuetify.breakpoint.smAndDown && showMenu" />
      <div class="cursor-pointer" @click.stop="redirectToHome">
        <img
          :src="
            $vuetify.theme.dark
              ? '/media/svg/logo-for-dark-theme.svg'
              : '/media/logos/logo_dark.svg'
          "
          alt="TestApp.io"
          class="logo-default mx-3"
          content
          height="30"
        />
      </div>
      <v-divider vertical />
      <v-btn
        v-if="showNavigationMenu"
        @click="$router.push({ name: 'dashboard' })"
        text
        class="iconColor--text text-transform-none text-hover-primary"
      >
        <span class="darkGrey--text"> Dashboard </span>
      </v-btn>
      <v-btn
        v-if="showNavigationMenu"
        @click="$router.push({ name: 'team' })"
        text
        class="iconColor--text text-transform-none text-hover-primary"
      >
        <span class="darkGrey--text"> Team </span>
      </v-btn>
      <v-btn
        v-if="showNavigationMenu"
        @click="$router.push({ name: 'apps' })"
        text
        class="iconColor--text text-transform-none text-hover-primary"
      >
        <span class="darkGrey--text"> Apps </span>
      </v-btn>
      <v-btn
        v-if="showNavigationMenu"
        @click="$router.push({ name: 'honest-feedback' })"
        text
        class="iconColor--text text-transform-none text-hover-primary"
      >
        <span class="success--text"> Honest feedback</span>
      </v-btn>
      <v-spacer v-if="!showMenu" />
      <v-spacer v-if="!showMenu" />
      <v-spacer></v-spacer>
      <div class="nav-menu__icon-links">
        <v-btn
          v-if="showNavigationMenu && getUniqueProgressList"
          id="upload-btn"
          icon
          @click="uploadProgress"
        >
          <v-badge
            :content="getUniqueProgressList"
            color="success"
            style="z-index: 1"
          >
            <v-icon class="" color="darkGrey">mdi-upload-outline</v-icon>
          </v-badge>
        </v-btn>
        <v-btn
          v-if="showNavigationMenu && !getUniqueProgressList"
          id="upload-btn"
          icon
          @click="uploadProgress"
        >
          <v-icon class="" color="darkGrey">mdi-upload-outline</v-icon>
        </v-btn>

        <v-btn icon @click="openProfileMenu">
          <v-icon color="darkGrey" icon v-text="'mdi-account-outline'"></v-icon>
        </v-btn>
        <template
          v-if="showMenu && $vuetify.breakpoint.mdAndUp && showNavigationMenu"
        >
          <v-btn
            v-if="totalNotifications"
            icon
            @click="$router.push({ name: 'user-notification' })"
          >
            <v-badge
              :content="
                `${totalNotifications}`.includes('-') ? '0' : totalNotifications
              "
              color="success"
              style="z-index: 1"
            >
              <v-icon class="" color="darkGrey">mdi-bell-outline</v-icon>
            </v-badge>
          </v-btn>
          <v-btn
            v-else
            icon
            @click="$router.push({ name: 'user-notification' })"
          >
            <v-icon color="darkGrey" v-text="'mdi-bell-outline'"></v-icon>
          </v-btn>
          <v-btn v-if="!showWhatsNewBadge" icon @click="openWhatsNewList">
            <v-badge color="success" dot style="z-index: 1">
              <v-icon color="darkGrey">mdi-newspaper</v-icon>
            </v-badge>
          </v-btn>
          <v-btn v-else icon @click="openWhatsNewList">
            <v-icon color="darkGrey" v-text="'mdi-newspaper'"></v-icon>
          </v-btn>
        </template>
        <v-btn class="mx-3" icon @click="updateDarkTheme()">
          <v-icon
            color="darkGrey"
            v-text="$vuetify.theme.dark ? 'mdi-lightbulb' : 'mdi-lightbulb-on'"
          ></v-icon>
        </v-btn>
        <v-menu
          v-if="getSocketMessage.show"
          :close-on-content-click="false"
          nudge-bottom="20"
          open-on-hover
        >
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-icon
                :color="getSocketMessage.color"
                class="mx-2 cursor-pointer"
              >
                mdi-wifi
              </v-icon>
            </span>
          </template>
          <v-card>
            <v-card-text>
              <div class="bodyFont">
                {{ getSocketMessage.message }}
              </div>
            </v-card-text>
            <v-card-actions v-if="getSocketMessage.color === 'error'">
              <v-spacer></v-spacer>
              <v-btn
                :outlined="$vuetify.theme.dark"
                class="text-transformP-none"
                color="primary"
                @click="reloadPage"
              >
                <v-icon left v-text="'mdi-reload'"></v-icon>
                Reload
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </v-app-bar>

    <!-- Uploads Queue -->
    <SidePanel
      v-model="uploadQueueDialog"
      :class="$vuetify.theme.dark ? 'navigationDark' : 'navigationFont'"
      height="100%"
      right
    >
      <v-card flat>
        <v-card-title>
          <v-spacer></v-spacer>
          <div class="titleFont primary--text">Uploads Queue</div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <feedback-uploading-menu
            @close="uploadQueueDialog = false"
          ></feedback-uploading-menu>
        </v-card-text>
      </v-card>
    </SidePanel>

    <SideNavigationMenu :drawer="drawer"></SideNavigationMenu>

    <!-- Quick Start -->
    <SidePanel
      v-model="showQuickStart"
      :permanent="false"
      temporary
      width="600"
      @close="showQuickStart = false"
    >
      <quick-start-checklist></quick-start-checklist>
    </SidePanel>

    <!-- Profile Drawer -->
    <SidePanel
      v-model="showProfileDrawer"
      permanent
      width="425"
      @close="showProfileDrawer = false"
    >
      <v-card class="pa-4" flat>
        <v-row>
          <v-col class="text-center" cols="12">
            <ProfileImage
              :alt="user?.name"
              :image="user?.image"
              :size="$vuetify.breakpoint.xsOnly ? 80 : 80"
              className="img-fluid align-self-center "
              from-side-bar
            />
          </v-col>
          <v-col class="text-center" cols="12">
            <div class="titleFont darkGrey--text">
              {{ user?.name }}
            </div>
            <div class="darkGrey--text bodyFont">
              {{ user?.email }}
            </div>
          </v-col>
          <v-col class="text-center" cols="12">
            <v-btn
              :outlined="$vuetify.theme.dark"
              class="text-transform-none mr-3"
              color="primary"
              @click="
                $router.push({ name: 'user-settings' }),
                  (showProfileDrawer = false)
              "
            >
              <v-icon left small> mdi-cogs</v-icon>
              Settings
            </v-btn>
            <v-btn
              :color="$vuetify.theme.dark ? 'primary' : ''"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              @click="onSIGNOUT"
            >
              <v-icon left small>exit_to_app</v-icon>
              Sign out
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col class="mr-1" cols="12" md="6">
                <v-btn
                  @click="openAppDownloadModal('android')"
                  color="primary"
                  outlined
                  class="text-transform-none"
                >
                  <v-icon left small> mdi-android</v-icon>
                  <span>Android app</span>
                </v-btn>
              </v-col>
              <v-col cols="12" md="">
                <v-btn
                  @click="openAppDownloadModal('ios')"
                  color="primary"
                  outlined
                  class="text-transform-none"
                >
                  <v-icon left small>mdi-apple</v-icon>
                  <span>iOS app</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <v-btn
              block
              class="text-transform-none"
              color="primary"
              outlined
              @click="openLink('community')"
            >
              <v-icon left v-text="'mdi-account-group'"></v-icon>
              <span class="font14"> Join our Community </span>
            </v-btn>
          </v-col>
          <v-col class="pt-0" cols="12">
            <v-btn
              block
              class="text-transform-none"
              color="primary"
              outlined
              @click="openLink('slack')"
            >
              <v-icon left v-text="'mdi-slack'"></v-icon>
              <span class="font14"> Join us on Slack </span>
            </v-btn>
          </v-col>
          <v-col class="pt-0" cols="12">
            <v-btn
              block
              class="text-transform-none"
              color="primary"
              outlined
              @click="openLink('')"
            >
              <v-icon left v-text="'mdi-linkedin'"></v-icon>
              <span class="font14"> Follow us on LinkedIn </span>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col class="text-center" cols="12">
            <a
              class="mt-3 mr-4"
              href="https://help.testapp.io/"
              target="_blank"
            >
              Help
            </a>
            <span class="cursor-pointer primary--text" @click="onContactus"
              >Contact us</span
            >

            <div class="mt-4">
              <a
                class="mt-3 mr-4"
                href="https://testapp.io/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>

              <a href="https://testapp.io/terms-and-conditions" target="_blank">
                Terms & Conditions
              </a>
            </div>
            <div class="text-center">
              <small>
                Latest release: {{ getLatestVersion }} -
                <span
                  ><vue-hoverable-date
                    :date="getLatestReleaseTimestamp"
                  ></vue-hoverable-date
                ></span>
              </small>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </SidePanel>

    <!-- Whats New List & Modals -->
    <v-card class="transparent" flat tile>
      <SidePanel
        v-model="showWhatsNewList"
        :permanent="false"
        temporary
        width="600"
        @close="showWhatsNewList = false"
      >
        <v-card-title>What's new</v-card-title>
        <whats-new
          v-if="showWhatsNewList"
          @updateId="setNewWhatsNewId"
        ></whats-new>
      </SidePanel>
      <Modal v-model="hideStartTrialModal" @close="hideStartTrialModal = false">
        <template #message>
          <v-row>
            <v-col cols="12">
              <div class="mt-4">
                <v-checkbox
                  :value="startTrialAlert"
                  label="Don't show this again"
                  @change="hideStartTrialAlert()"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col cols="12">
              <v-btn
                class="text-transform-none"
                color="primary"
                depressed
                @click="hideStartTrialAlert('hideOnly')"
              >
                Hide
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </Modal>
      <Modal
        v-model="showFeedbackForm"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :width="$vuetify.breakpoint.smAndDown ? '' : 600"
        noPy
        @close="closeFeedbackForm"
      >
        <template #message>
          <feedback-form flat @close="showFeedbackForm = false"></feedback-form>
        </template>
      </Modal>
      <Modal
        v-model="createTeamModal"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        @close="createTeamModal = false"
      >
        <template v-if="createTeamModal" #title>
          <v-spacer></v-spacer>
          <div class="darkGrey--text">Create new team</div>
          <v-spacer></v-spacer>
        </template>
        <template #message>
          <create-team
            from-switch-team
            @teamCreated="afterSuccess"
          ></create-team>
        </template>
      </Modal>
      <Modal v-model="teamEditDialog" @close="teamEditDialog = false">
        <!--  -->
        <template #message>
          <update-team-info
            v-if="teamEditDialog"
            @close="closeUpdateDrawer"
          ></update-team-info>
        </template>
        <!--  -->
      </Modal>
      <Modal v-model="openApp" :fullscreen="$vuetify.breakpoint.smAndDown">
        <template #message>
          <v-card-title class="display-1 justify-center mb-4">
            Add App
          </v-card-title>
          <v-card-text>
            <create-app-step
              v-if="openApp"
              from-create-panel
              @close="openApp = false"
            ></create-app-step>
          </v-card-text>
        </template>
      </Modal>

      <Modal v-model="viewEditApp" hideOverlay @close="viewEditApp = false">
        <template #message>
          <EditAppPanel
            :app="appInfo"
            @close="viewEditApp = false"
            @success="$emit('success', $event)"
          />
        </template>
      </Modal>
      <Modal v-model="deleteModal" no-py>
        <template #card>
          <v-card>
            <v-card-title class="font-weight-bold">Delete app</v-card-title>
            <v-divider />
            <v-card-text class="my-4 message">
              <p>Are you sure you want to delete this app?</p>

              <v-alert
                border="left"
                class="mt-3"
                color="info"
                icon="info"
                outlined
              >
                You will lose the access to any information regarding this app.
                <div v-if="readMore">
                  <br />
                  This app will be deleted along with everything attached to it
                  such as:<br />
                  <br />

                  <ul>
                    <li>Releases</li>
                    <li>Timeline</li>
                    <li>Notifications</li>
                  </ul>

                  <br />
                </div>
                <b
                  v-if="!readMore"
                  class="pointer"
                  @click="readMore = !readMore"
                >
                  {{ "Read more..." }}
                </b>
                <br />
              </v-alert>
            </v-card-text>
            <v-divider />
            <v-card-actions class="py-3">
              <div>No undo for this action</div>
              <v-spacer />
              <v-btn
                id="leaveAppCancelBtn"
                class="text-transform-none"
                color="primary"
                text
                @click="onCancel"
                >No
              </v-btn>
              <v-btn
                id="leaveAppConfirmBtn"
                :loading="loading"
                :outlined="$vuetify.theme.dark"
                class="text-transform-none"
                color="primary"
                @click="deleteAppConfirm"
                >Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </Modal>
      <Modal v-model="modal" no-py>
        <template #card>
          <leave-team-modal flat @close="onCancel"></leave-team-modal>
        </template>
      </Modal>
      <Modal
        v-model="showNotificationSetting"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :width="$vuetify.breakpoint.mdAndUp ? 860 : ''"
        noPy
        @close="showNotificationSetting = false"
      >
        <template #message>
          <manage-settings
            v-if="showNotificationSetting"
            @close="closeManageSettings"
          ></manage-settings>
        </template>
      </Modal>
      <Modal
        v-model="openTrialModal"
        :fullscreen="$vuetify.breakpoint.mdAndDown"
        :width="$vuetify.breakpoint.mdAndUp ? 984 : ''"
        no-py
        @close="openTrialModal = false"
      >
        <template #message>
          <start-trial-modal
            from-menu
            @close="openTrialModal = false"
          ></start-trial-modal>
        </template>
      </Modal>
      <Modal
        v-model="editUserProfile"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :width="$vuetify.breakpoint.mdAndUp ? '600' : ''"
        noPy
        @close="editUserProfile = false"
      >
        <template #card>
          <v-card v-if="editUserProfile" class="pa-2" flat>
            <v-card-title class="bold-text bodyFont" cols="12">
              <div>Edit profile</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="editUserProfile = false">
                <v-icon v-text="'mdi-close'"></v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <edit-profile
                class="my-4"
                v-if="editUserProfile"
                @close="editUserProfile = false"
              ></edit-profile>
            </v-card-text>
          </v-card>
        </template>
      </Modal>
      <Modal v-model="appStoreLinkModal" @close="appStoreLinkModal = false">
        <template #card>
          <download-app-modal
            v-if="appStoreLinkModal"
            :store-link="storeLink"
            @close="appStoreLinkModal = false"
          ></download-app-modal>
        </template>
      </Modal>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
// import UserProfile from "@/view/layout/extras/dropdown/DropdownProfileAction.vue";
import FeedbackForm from "@/view/components/Common/FeedbackForm";
// import FeedbackMenu from "@/view/components/Common/FeedbackMenu";
import StorageService from "@/core/services/localstorage.service";
import { formatBytes, get, isEmpty } from "@/core/services/helper.service";
import WhatsNew from "@/view/components/User/Notification/WhatsNew.vue";
import { socialMediaInfo } from "@/view/layout/SideNavigationMenu/const";
import CreateTeam from "@/view/components/Team/Create";
import {
  GET_BROADCAST_INFO,
  REMOVE_IDENTITY_FOR_TRACKER,
  SIGNOUT,
  UPDATE_DARK_THEME,
  SSO_AUTH,
} from "@/store/users/auth.module";
import UpdateTeamInfo from "@/view/components/Team/UpdateTeamInfo";
import CreateAppStep from "@/view/components/App/CreateAppStep";
// import AppImageEdit from "@/view/components/App/AppImageEdit";
import EditAppPanel from "@/view/components/App/EditAppPanel.vue";
import { DELETE_APP } from "@/store/apps/app.module";
import ManageSettings from "@/view/components/User/Notification/ManageSettings";
import EditProfile from "@/view/components/User/EditProfile";
import { destroyToken } from "@/core/services/jwt.service";
import StartTrialModal from "@/view/components/Common/StartTrialModal";
import DownloadAppModal from "@/view/components/Common/DownloadAppModal";
import QuickStartChecklist from "@/view/components/Common/QuickStartChecklist";
import FeedbackUploadingMenu from "@/view/components/Releases/FeedbackUploadingMenu";
// import ReleasesUploadingMenu from "@/view/components/Releases/ReleasesUploadingMenu";
// import WhatNewCard from "@/view/components/Common/WhatNewCard";
import LeaveTeamModal from "@/view/components/Team/LeaveTeamModal";
import pageRoutes from "@/consts/routes/pageRoutes";
import SideNavigationMenu from "@/view/layout/SideNavigationMenu/SideNavigationMenu.vue";

export default {
  components: {
    LeaveTeamModal,
    // ReleasesUploadingMenu,
    FeedbackUploadingMenu,
    DownloadAppModal,
    QuickStartChecklist,
    // WhatNewCard,
    StartTrialModal,
    EditProfile,
    ManageSettings,
    // AppImageEdit,
    CreateAppStep,
    UpdateTeamInfo,
    CreateTeam,
    // FeedbackMenu,
    FeedbackForm,
    // UserProfile,
    WhatsNew,
    EditAppPanel,
    SideNavigationMenu,
  },
  name: "KTHeader",
  tagColor: {
    new: "pink",
    improvement: "orange",
    announcement: "success",
    news: "primary",
  },
  data() {
    return {
      isEmpty,
      get,
      formatBytes,
      socialMediaInfo,
      menu: false,
      teamActionModal: false,
      selectedAction: 1,
      teamActions: [
        {
          name: "Switch Teams",
          icon: "mdi-transit-transfer",
          value: 1,
        },
        {
          name: "Create Team",
          icon: "mdi-plus",
          value: 2,
        },
        {
          name: "Edit Team",
          icon: "edit",
          value: 3,
        },
        {
          name: "Leave Team",
          icon: "logout",
          value: 4,
        },
      ],
      themeChoice: "light",
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Skip tour",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Ok",
        },
      },
      myCallbacks: {
        onStop: this.stopTour,
      },
      steps: [
        {
          target: "#upload-btn",
          header: {
            title: "Uploads Queue",
          },
          content: `App releases and feedback attachments upload progress will be shown here. <br /><br />You can navigate Portal freely while uploads are in progress.`,
        },
      ],
      showProfileDrawer: false,
      showInfoBanner: true,
      modal: false,
      showQuickStart: false,
      teamMenu: false,
      storeLink: {},
      appStoreLinkModal: false,
      loading: false,
      openWhatsNewModal: true,
      loadingConfirm: false,
      openRelease: false,
      deleteModal: false,
      openTrialModal: false,
      viewEditApp: false,
      editUserProfile: false,
      openApp: false,
      drawer: true,
      selectedItem: 1,
      teamEditDialog: false,
      createTeamModal: false,
      showNotificationSetting: false,
      socialMedia: [
        {
          link: "https://www.linkedin.com/company/testappio",
          name: "LinkedIn",
          icon: "mdi-linkedin",
        },
        {
          link: "https://twitter.com/TestAppio",
          name: "Twitter",
          icon: "mdi-twitter",
        },
        {
          link: "https://join.slack.com/t/testappio/shared_invite/zt-pvpoj3l2-epGYwGTaV3~3~0f7udNWoA",
          name: "Slack",
          icon: "mdi-slack",
        },
        {
          link: "https://roadmap.testapp.io/",
          name: "Request a Feature",
          icon: "mdi-star-circle-outline",
        },
      ],
      emojiRating: [
        {
          icon: "mdi-emoticon-cool",
          value: 3,
        },
        {
          icon: "mdi-emoticon-excited",
          value: 2,
        },
        {
          icon: "mdi-emoticon-cry",
          value: 0,
        },
      ],
      showNotificationDrawer: false,
      hideStartTrialModal: false,
      showFeedbackForm: false,
      leaveModal: false,
      readMore: false,
      busy: false,
      dashboard: "",
      feedBackRating: 0,
      hideStartTrial: false,
      startHideAction: false,
      lastNewId: "",
      hideActiveTrial: false,
      intercomLoading: false,
      intercomeMessage: { message: "Hello", subject: "Hello" },
      whatNewLoading: false,
      uploadQueueDialog: false,
      mini: true,
      themeLoading: false,
      showWhatsNewList: false,
      navMenuLinks: [
        { label: "Home", route: pageRoutes.home },
        { label: "Help", route: "https://help.testapp.io/" },
        { label: "Blog", route: "https://blog.testapp.io/" },
        {
          label: "Integrations",
          route: "https://help.testapp.io/topic/integrations/",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getApps: "getApps",
      getSSOAuthQuery: "getSSOAuthQuery",
      getUploadQueueTour: "getUploadQueueTour",
      appInfo: "getAppInfo",
      getApkFile: "getApkFile",
      getTotalProgress: "getTotalProgress",
      getAttachmentsList: "getAttachmentsList",
      ipaFileInfo: "getIpaFile",
      getDarkTheme: "getDarkTheme",
      userRoles: "userRoles",
      userPermissions: "userPermissions",
      getIOSProgress: "getIOSProgress",
      getAndroidProgress: "getAndroidProgress",
      getSocketMessage: "getSocketMessage",
      getFeedbackModalDetail: "getFeedbackModalDetail",
      userNotifications: "userNotificationsStats",
      isAuthenticated: "isAuthenticated",
      getNavigateTo: "getNavigateTo",
      getTeamName: "getTeamName",
      getTeamId: "getTeamId",
      getKycFormStatus: "getKycFormStatus",
      getIsUserDisabled: "getIsUserDisabled",
      getBroadCastMessage: "getBroadCastMessage",
      isSocketDisconnected: "getIsSocketDisconnected",
      getTeamTrial: "getTeamTrial",
      getIsUserDisabled: "getIsUserDisabled",
      getReleaseProgressList: "getReleaseProgressList",
      getWhatsNewLastReadId: "getWhatsNewLastReadId",
      whatsNewList: "getWhatsNewList",
    }),
    showNavigationMenu() {
      const isPartofTeam = !!this.$store.getters.getTeamId;
      const isKycComplete = this.$store.getters.getKycFormStatus;

      if (!isPartofTeam || !isKycComplete) return false;

      return (
        !["inactive", "kyc", "welcome"].includes(this.$route.name) &&
        !this.$vuetify.breakpoint.smAndDown
      );
    },
    menuList() {
      return [
        {
          title: "Dashboard",
          component: "app-dashboard",
          link: "dashboard",
          show: !!this.canManageApp,
          icon: "mdi-view-dashboard",
          value: 0,
        },
        {
          title: "Chat",
          component: "app-chat",
          tag: "",
          link: "chat",
          show: !!this.canViewChat,
          icon: "mdi-chat-outline",
          value: 1,
        },
        {
          title: "Releases",
          component: "app-releases",
          tag: "",
          link: "releases",
          show: true,
          icon: "mdi-package-variant-closed",
          value: 2,
        },
        {
          title: "Sessions",
          show: !!this.canManageApp,
          icon: "mdi-cogs",
          link: "sessions",
          badge: {
            label: "New",
            color: "success",
          },
        },
        {
          title: "Integrations",
          component: "app-integrations",
          link: "integration",
          badge: {
            label: "New",
            color: "success",
          },
          show: !!this.canManageApp,
          icon: "mdi-transit-connection",
          value: 6,
        },
        {
          title: "Settings",
          badge: {
            label: "Updated",
            color: "primary",
          },
          component: "app-settings",
          link: "settings",
          show: true,
          icon: "mdi-settings",
          value: 8,
        },
      ];
    },
    items() {
      return [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          link: "dashboard",
          to: "/",
        },
        {
          title: "Team",
          show: !!this.canViewTeamMembers,
          icon: "mdi-account-group",
          children: [
            {
              title: "Members",
              icon: "mdi-account-group-outline",
              to: "/team/members",
              show: !!this.canViewTeamMembers,
            },
            {
              title: "Invites",
              icon: "mdi-account-multiple-plus-outline",
              to: "/team/invites",
              show: !!this.canViewInvite,
            },
            {
              title: "Storage",
              icon: "mdi-database",
              show: !!this.canViewStorage,
              to: "/team/storage",
            },
            {
              title: "Integrations",
              icon: "mdi-settings",
              show: !!this.canManageTeam,
              to: "/team/integrations",
            },
            {
              title: "Plan & Usage",
              icon: "mdi-cash",
              show: !!this.canManageTeam,
              to: "/team/usage",
            },
          ],
        },
        { title: "Apps", link: "apps", icon: "mdi-apps", to: "/apps" },
        {
          title: "Sessions",
          show: !!this.canViewTeamMembers,
          icon: "mdi-cogs",
          children: [
            {
              title: "Dashboard",
              icon: "mdi-account-group-outline",
              to: "/sessions/overview",
              show: true,
            },
            {
              title: "Users",
              icon: "mdi-cellphone-link",
              to: "/sessions/users",
              show: true,
            },
            {
              title: "Devices",
              icon: "mdi-device",
              to: "/sessions/devices",
              show: true,
            },
            {
              title: "Applications",
              icon: "mdi-app",
              to: "/sessions/applications",
              show: true,
            },
            {
              title: "Activities",
              icon: "mdi-cash",
              to: "/sessions/activities",
              show: true,
            },
            {
              title: "Feedback",
              icon: "mdi-feedback",
              to: "/sessions/feedback",
              show: true,
            },
          ],
        },
      ];
    },
    ...mapGetters(["getClasses"]),
    ...mapState({
      user: (state) => state.auth.user,
    }),
    getUniqueProgressList() {
      const releaseUniq = [
        ...new Map(this.getReleaseProgressList.map((v) => [v.id, v])).values(),
      ];
      const feedbackCount =
        this.getAttachmentsList.filter((x) => x.uploadStatus === "uploading")
          .length || 0;
      const releaseCount =
        releaseUniq.filter((x) => x.status === "uploading").length || 0;
      return feedbackCount + releaseCount || 0;
    },
    currentVersionCode() {
      return process.env.VUE_APP_RELEASE_CODE;
    },
    acronym() {
      let acronym = "";
      try {
        acronym = this.getTeamName.match(/\b\w/g).join("").substring(0, 2);
      } catch (e) {
        acronym = "";
      }
      return acronym;
    },
    showMenu() {
      return ![
        "welcome",
        "get-started",
        "started-team",
        "started-app",
        "download-app",
        "started-releases",
        "started-invite",
      ].includes(this.$route.name);
    },
    showAppMenu() {
      return [
        "app-dashboard",
        "app-releases",
        "app-members",
        "app-chat",
        "app-share-link",
        "app-subscribers",
        "app-share-settings",
        "app-feedback",
        "app-installs",
        "app-integrations",
        "app-settings",
        "app-feedback-detail",
        "app-feedback-attachment-detail",
        "app-settings-invite",
        "app-settings-release",
        "app-settings-information",
        "app-sessions",
        "sessions-detail",
        "app-sessions-users",
        "app-sessions-devices",
        "app-sessions-applications",
        "app-sessions-activities",
        "app-sessions-feedback",
      ].includes(this.$route.name);
    },
    showStartTrialAlert() {
      return (
        isEmpty(this.getTeamTrial) &&
        !!this.getTeamId &&
        !this.getIsUserDisabled
      );
    },
    showActiveTrialAlert() {
      return (
        !isEmpty(this.getTeamTrial) && this.getTeamTrial.status === "active"
      );
    },
    showTrialEnded() {
      return (
        !isEmpty(this.getTeamTrial) && this.getTeamTrial.status === "ended"
      );
    },
    startTrialAlert: {
      get: function () {
        return (
          StorageService.getItem("alert") &&
          StorageService.getItem("alert").start_trial
        );
      },
      set: function (v) {
        const alert = StorageService.getItem("alert");
        StorageService.setItem("alert", {
          ...alert,
          start_trial: v,
        });
      },
    },
    isActiveApp() {
      return [
        "app",
        "app-dashboard",
        "releases",
        "timeline",
        "members",
      ].includes(this.$route.name);
    },
    lastNewIdFromLocalStore() {
      return this.lastNewId || StorageService.getItem("last_whats_new_read_id");
    },
    lastNewModalReadIdFromLocalStore() {
      return StorageService.getItem("last_whats_new_read_popup_id");
    },
    showWhatsNewReadModal: {
      get() {
        return !(
          this.getWhatsNewLastReadId === this.lastNewModalReadIdFromLocalStore
        );
      },
      set() {
        return false;
      },
    },
    getTourInfo() {
      let info = JSON.parse(localStorage.getItem("tour"));

      if (info && info.upload_queue === "true") {
        return false;
      } else {
        return true;
      }
    },
    showWhatsNewBadge() {
      return this.getWhatsNewLastReadId === this.lastNewIdFromLocalStore;
    },

    totalNotifications() {
      if (this.userNotifications.alerts + this.userNotifications.invites > 99) {
        return "99+";
      } else {
        return this.userNotifications.alerts + this.userNotifications.invites;
      }
    },
    getLatestVersion() {
      return "v" + process.env.VUE_APP_RELEASE.split("@")[1];
    },
    getLatestReleaseTimestamp() {
      return process.env.VUE_APP_RELEASE_DATE;
    },
  },
  watch: {
    getTeamTrial(val) {
      if (!isEmpty(val) && val.status !== "active") {
        this.hideStartTrial = true;
      }
    },
    getUploadQueueTour(val) {
      if (this.getTourInfo && val) {
        this.startTour();
      }
    },
    getUniqueProgressList(val) {
      if (val === "0") {
        this.setIsUploadingReleases(false);
      }
    },
    getFeedbackModalDetail(val) {
      if (val) {
        this.showFeedbackForm = true;
      }
    },
    startTrialAlert(val) {
      if (val) {
        this.hideStartTrialAlert();
      }
    },
    getTeamId(val) {
      if (val) {
        this.hideStartTrial = false;
      } else {
        this.hideStartTrial = true;
      }
    },
  },
  created() {
    if (this.getSSOAuthQuery || this.$route?.query?.sso) {
      this.$store
        .dispatch(SSO_AUTH, this.getSSOAuthQuery || this.$route.query)
        .then((response) => {
          window.open(response.url, "_self");
          this.setSSOAuthQuery(null);
          return;
        });
    }
    if (this.getNavigateTo && this.getNavigateTo === "notification_settings") {
      this.showNotificationSetting = true;
    }
    this.setRedirectionUrl({});
    if (isEmpty(this.userRoles)) {
      this.getUserInfo();
    } else {
      if (this.getTeamId) {
        this.connectToSocket();
      }
    }
  },
  mounted() {
    if (
      !isEmpty(this.getTeamTrial) &&
      this.getTeamTrial.status === "active" &&
      !!this.activeTrialAlert
    ) {
      this.hideActiveTrial = false;
    }
    this.hideActiveTrial = !!this.activeTrialAlert;
    if (!isEmpty(this.getTeamTrial)) {
      this.hideStartTrial = true;
    }
    if (!this.getTeamId) {
      this.hideStartTrial = true;
    }
    this.hideStartTrial = !!this.startTrialAlert;
    if (this.getDarkTheme) {
      this.$vuetify.theme.dark = true;
      this.darkTheme = true;
    }
    if (StorageService.getItem("dark-theme")) {
      this.$vuetify.theme.dark = true;
      this.darkTheme = true;
    }
    this.$nextTick(() => {
      let query = this.$route?.query;
      if (query.hasOwnProperty("open-chat")) {
        if (window?.intercom) {
          window.Intercom("showNewMessage", this.intercomeMessage.message);
        } else {
          setTimeout(() => {
            this.openIntercomChat(this.intercomeMessage);
          }, 2000);
        }
      }
    });
    this.fetchBroadCastMessage();
  },
  methods: {
    ...mapMutations({
      setRedirectionUrl: "setRedirectionUrl",
      setSSOAuthQuery: "setSSOAuthQuery",
      setIsUploadingReleases: "setIsUploadingReleases",
      removeAppStats: "removeAppStats",
      setFeedbackModalDetail: "setFeedbackModalDetail",
      setBroadcastMessage: "setBroadcastMessage",
      setDarKTheme: "setDarKTheme",
      setPortalUpdateInfo: "setPortalUpdateInfo",
      removeAppFromList: "removeAppFromList",
      setNavigateTo: "setNavigateTo",
    }),
    redirectToHome() {
      this.$router.push({ name: "dashboard" });
    },
    openAppDownloadModal(platform) {
      const url =
        platform === "android"
          ? "https://play.google.com/store/apps/details?id=testapp.io"
          : "https://apps.apple.com/us/app/testapp-io/id1518972541";
      this.storeLink = {
        platform,
        url,
      };
      this.appStoreLinkModal = true;
    },
    openEditProfileDialog() {
      this.editUserProfile = true;
    },
    openSocialMediaLink(link) {
      window.open(link, "blank");
    },
    stopTour() {
      localStorage.setItem(
        "tour",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("tour")),
          upload_queue: "true",
        })
      );
    },
    startTour() {
      this.$tours["headerTour"]?.start();
    },
    uploadProgress() {
      this.uploadQueueDialog = true;
    },
    openLink(type) {
      if (type == "slack") {
        window.open(
          "https://join.slack.com/t/testappio/shared_invite/zt-pvpoj3l2-epGYwGTaV3~3~0f7udNWoA",
          "_blank"
        );
      } else if (type === "community") {
        window.open("https://community.testapp.io", "_blank");
      } else {
        window.open("https://www.linkedin.com/company/testappio", "_blank");
      }
    },
    reloadPage() {
      window.location.reload();
    },
    openProfileMenu() {
      this.showProfileDrawer = true;
    },
    closeFeedbackForm() {
      this.setFeedbackModalDetail(false);
      this.showFeedbackForm = false;
    },
    dismissBroadcastMessage() {
      this.setBroadcastMessage({});
    },
    fetchBroadCastMessage() {
      this.$store
        .dispatch(GET_BROADCAST_INFO)
        .then(() => {})
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        });
    },
    setDarkThemeLocalStore(val) {
      StorageService.setItem("dark-theme", val);
    },
    updateDarkTheme() {
      if (this.themeLoading) return;
      this.themeLoading = true;
      const dataToPost = {
        name: this.$vuetify.theme.dark ? "light" : "dark",
        platform: "portal",
        device_id: "",
      };
      this.setDarKTheme(dataToPost.name);
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.setDarkThemeLocalStore(dataToPost.name === "dark");
      this.$store
        .dispatch(UPDATE_DARK_THEME, dataToPost)
        .then(() => {
          this.setDarKTheme(dataToPost.name);
          this.setDarkThemeLocalStore(dataToPost.name === "dark");
          this.themeLoading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.themeLoading = false;
        });
    },
    openNotificationSetting() {
      this.showNotificationSetting = true;
    },
    closeManageSettings() {
      this.showNotificationSetting = false;
      this.selectedItem = null;
      this.setNavigateTo("");
    },
    async afterSuccess(plan) {
      this.createTeamModal = false;
      this.teamActionModal = false;
      if (["Pro", "Starter"].includes(plan)) {
        await this.$router.push({ name: "team-plan" });
      } else if (this.$route.params && this.$route.params.app_id) {
        await this.$router.push({ name: "apps" });
      }
      this.showSuccessTeamSwitch = true;
      this.$nextTick(() => {
        window.location.reload();
      });
    },
    deleteAppConfirm() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(DELETE_APP, this.appInfo.id)
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
          this.removeAppFromList(this.appInfo.id);
          this.removeAppStats({
            appCount: 1,
          });
          this.$router.push({ name: "apps" }).catch(() => {});
          this.loading = false;
          this.deleteModal = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    getUserInfo() {
      this.$store
        .dispatch("getUserRoles")
        .then((response) => {
          if (!this.canManageTeam) {
            this.teamActions = this.teamActions.filter((x) => x.value !== 3);
          }
          if (
            parseInt(this.currentVersionCode) <
            parseInt(get(response, "config.portal.version_code", null))
          ) {
            this.setPortalUpdateInfo({
              showReleaseUpdate: true,
              versionInfo: response.config.portal,
            });
          }

          if (this.getTeamId) {
            this.connectToSocket();
          }
        })
        .catch((err) => {
          this.notifyErrorMessage({ message: err.message });
        });
    },
    closeUpdateDrawer() {
      this.teamEditDialog = false;
    },
    onCancel() {
      this.deleteModal = false;
      this.leaveModal = false;
      this.teamActionModal = false;
    },
    hideStartTrialAlert(hideOnly) {
      if (!hideOnly && this.startHideAction) {
        this.startTrialAlert = "true";
      } else if (!hideOnly) {
        this.activeTrialAlert = "true";
      }
      this.hideActiveTrial = true;
      this.hideStartTrial = true;
      this.hideStartTrialModal = false;
    },
    onSIGNOUT() {
      if (this.loading) return;
      this.loading = true;
      this.teamMenu = false;
      destroyToken();
      this.$store.commit(REMOVE_IDENTITY_FOR_TRACKER);
      this.$router.push({ name: "signin" }).catch(() => {});
      this.$store
        .dispatch(SIGNOUT)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openWhatsNewList() {
      this.showWhatsNewList = true;
    },
    setNewWhatsNewId() {
      this.lastNewId = StorageService.getItem("last_whats_new_read_id");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.v-list .v-list-item {
  min-height: 35px;
}

.scrollBar {
  height: 400px;
  -webkit-overflow-scrolling: auto;
  overflow-y: scroll;
}

.v-step__header {
  background-color: #ffffff !important;
  color: #0e48dd;
}

.infoBanner {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 5;
}

.scrollWhatNewBox {
  height: 400px;
  overflow-y: scroll;
}

.menu-btn.v-btn {
  background-color: white !important;
  border: 2px solid rgba($grey, 0.5);
  border-radius: 8px;
  color: $text-color !important;
  height: 45px !important;
  min-width: 45px !important;
  padding: 0 !important;

  &::before {
    display: none;
  }
}

.v-toolbar__content {
  padding-left: 0;
  padding-right: 0;
}

.nav-menu__links {
  align-items: center;
  display: flex;
  gap: 20px;
}

.nav-menu__icon-links {
  align-items: center;
  display: flex;
  gap: 4px;
  margin-left: 12px;
}
</style>
