var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_c('div', [_c('div', {
    staticClass: "bold-text"
  }, [_vm._v("Notification settings")]), _c('div', {
    staticClass: "text-muted bodyFont"
  }, [_vm._v(" We'll never spam you. We hate it too! ")])])]), _c('v-card-text', [_vm.loading ? _c('div', [_c('v-skeleton-loader', {
    attrs: {
      "type": "article, actions"
    }
  })], 1) : _c('div', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_vm.errorMessage ? _c('v-col', {
    staticClass: "py-15",
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "with-button": "",
      "first-text": "Ops!",
      "second-text": _vm.errorMessage
    }
  })], 1) : _vm._e(), !_vm.errorMessage ? [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }), _c('v-col', {
    staticClass: "bold-text",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Email ")]), _c('v-col', {
    staticClass: "bold-text",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Push ")])] : _vm._e()], 2), !_vm.errorMessage ? _vm._l(_vm.showItems, function (item, index) {
    return _c('v-row', {
      key: index,
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "font-size-h4",
      attrs: {
        "cols": "8"
      }
    }, [_c('div', {
      staticClass: "bold-text pb-2"
    }, [_vm._v(" " + _vm._s(_vm.settingsDesc[item] ? _vm.settingsDesc[item].title : "") + " ")]), _c('div', {
      staticClass: "text-muted bodyFont",
      domProps: {
        "innerHTML": _vm._s(_vm.settingsDesc[item] ? _vm.settingsDesc[item].description : '')
      }
    })]), _c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "id": "emailSettingCheckbox".concat(index),
        "hide-details": "",
        "disabled": item === 'essentials'
      },
      model: {
        value: _vm.settings[item].email,
        callback: function callback($$v) {
          _vm.$set(_vm.settings[item], "email", $$v);
        },
        expression: "settings[item].email"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "id": "pushSettingCheckbox".concat(index),
        "hide-details": "",
        "disabled": item === 'essentials'
      },
      model: {
        value: _vm.settings[item].push,
        callback: function callback($$v) {
          _vm.$set(_vm.settings[item], "push", $$v);
        },
        expression: "settings[item].push"
      }
    })], 1)], 1);
  }) : _vm._e(), _vm.$route.query.action ? _c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.showMore = !_vm.showMore;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.showMore ? "Show less" : "View all notifications") + " ")])], 1)], 1) : _vm._e()], 2)]), !_vm.loading && !_vm.errorMessage ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "depressed": "",
      "loading": _vm.loading,
      "outlined": _vm.$vuetify.theme.dark,
      "disabled": _vm.loading || !!_vm.errorMessage,
      "id": "updateNotificationSettingBtn",
      "color": "primary"
    },
    on: {
      "click": _vm.updateSettings
    }
  }, [_vm._v(" Save ")])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }