var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('div', [_c('v-row', _vm._l(4, function (i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "article, actions"
      }
    })], 1);
  }), 1)], 1) : _c('div', [_vm.whatsNewList && _vm.whatsNewList.length ? _c('div', _vm._l(_vm.whatsNewList, function (item, index) {
    return _c('v-card', {
      key: index,
      staticClass: "my-4 lightBorder",
      attrs: {
        "outlined": "",
        "elevation": "1"
      }
    }, [_c('what-new-card', {
      attrs: {
        "detail": item
      }
    })], 1);
  }), 1) : _c('div', [_c('v-col', {
    staticClass: "no-data-found"
  }, [_c('inline-svg', {
    staticClass: "mt-6 svg-icon empty_icon",
    attrs: {
      "src": "/media/placeholders/error.svg"
    }
  }), _c('p', {
    staticClass: "mt-4 empty-title bold-text text-dark text-center"
  }, [_vm._v(" No new updates found. ")]), _c('p', {
    staticClass: "mt-4 mb-5 empty-sub-title font-weight-normal text-dark text-center"
  })], 1)], 1)]), _c('infinate-loading-base', {
    attrs: {
      "endpoint": _vm.endpoint,
      "reset": true,
      "distance": 200
    },
    on: {
      "update-loading": function updateLoading($event) {
        _vm.loading = $event;
      },
      "setInfiniteResponse": _vm.setInfiniteResponse,
      "resetInfiniteResponse": _vm.resetInfiniteResponse
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }