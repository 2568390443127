<template>
  <v-row class="my-3">
    <v-col cols="12" class="text-center text-muted">
      <div>- - - - - - - Or - - - - - - -</div>
    </v-col>
    <v-col cols="12" class="text-center">
      <v-btn
        class="text-transform-none"
        :class="$vuetify.breakpoint.smAndDown ? 'mb-3' : 'mr-3 px-2'"
        large
        color="grey"
        outlined
        :block="$vuetify.breakpoint.smAndDown"
        :disabled="busy"
        @click="socialAuth('google')"
      >
        <v-row align="center">
          <v-col class="pr-0" cols="3">
            <v-img
              width="48"
              height="48"
              src="/media/svg/icons/google-icon.svg"
            ></v-img>
          </v-col>
          <v-col class="pl-0" cols="9">
            <span class="darkGrey--text">
              {{ buttonText }}
              Google
            </span>
          </v-col>
        </v-row>
      </v-btn>
      <v-btn
        @click="socialAuth('apple')"
        :color="$vuetify.theme.dark ? 'primary' : 'black'"
        large
        :block="$vuetify.breakpoint.smAndDown"
        depressed
        :disabled="busy"
        :outlined="$vuetify.theme.dark"
        :class="$vuetify.theme.dark ? '' : 'white--text'"
        class="text-transform-none pr-6"
      >
        <v-row>
          <v-col class="pr-0" cols="3">
            <v-icon left color="white" size="23" v-text="'mdi-apple'"></v-icon>
          </v-col>
          <v-col class="pl-0" cols="9">
            <span>
              {{ buttonText }}
              Apple
            </span>
          </v-col>
        </v-row>
      </v-btn>
    </v-col>
    <Modal v-model="successAuthModal" hideClose>
      <template #message>
        <v-row class="py-4">
          <v-col class="text-center" cols="12">
            <div class="text-h6" v-if="!firebaseUserName">
              We are authenticating your account, please wait...
            </div>
            <div class="text-h5" v-else>Welcome {{ firebaseUserName }}!</div>
          </v-col>
          <v-col v-if="firebaseUserName" class="text-center text-h6" cols="12">
            We are signing you in, please wait...
          </v-col>

          <v-col class="text-center" cols="12">
            <v-progress-circular
              indeterminate
              color="success"
            ></v-progress-circular>
          </v-col>
          <v-col class="text-center bodyFont" cols="12">
            It should be done within few moments
          </v-col>
        </v-row>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import firebase from "firebase";
import { SIGNIN_WITH_TOKEN } from "@/store/users/auth.module";
import { mapMutations } from "vuex";
import StorageService from "@/core/services/localstorage.service";
import { getCookie } from "@/core/services/helper.service";

export default {
  props: {
    buttonText: {
      type: String,
      default: "",
    },
    defaultSignin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      busy: false,
      successAuthModal: false,
      firebaseUserName: "",
      form: {
        invite_id: "",
        referral: {
          type: "",
          name: "",
        },
      },
    };
  },
  created() {
    if (this.$route.params.invite_id) {
      this.form.invite_id = this.$route.params.invite_id;
    }

    const irclickid = getCookie("irclickid");
    if (irclickid) {
      this.form.referral.type = "impactRadius";
      this.form.referral.name = irclickid;
    }
  },
  methods: {
    ...mapMutations({
      setAuthMessage: "setAuthMessage",
    }),
    socialAuth(type) {
      this.setAuthMessage({ message: "", type: "" });
      if (this.busy) return;
      this.busy = true;
      this.$emit("loading", true);
      // For apple
      const appleProvider = new firebase.auth.OAuthProvider("apple.com");

      //For Google
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      const provider = type === "google" ? googleProvider : appleProvider;

      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          this.successAuthModal = true;
          this.firebaseUserName = result.user.displayName;
          firebase
            .auth()
            .currentUser.getIdToken(false)
            .then((idToken) => {
              // Send idToken" to our API to get the Signin info
              this.signWithToken(idToken);
            })
            .catch((error) => {
              this.notifyErrorMessage(error);
              this.busy = false;
              this.$emit("loading", false);
            });
          // This is the authentication token you can use to authenticate with the social provider's API
        });
      StorageService.setItem("social_auth", "true");
    },
    signWithToken(token) {
      this.$store
        .dispatch(SIGNIN_WITH_TOKEN, {
          token: token,
          ...this.form,
        })
        .then(async (response) => {
          await firebase.auth().signOut();
          if (this.defaultSignin) {
            this.successAuthModal = false;
            this.$nextTick(() => {
              if (response && !response.team) {
                this.$router.push({ name: "welcome" }).catch(() => {});
              } else {
                this.$router.push({ name: "dashboard" }).catch(() => {});
              }
            });
          } else {
            this.successAuthModal = false;
            this.$emit("success", response.message);
          }
          this.busy = false;
          this.$emit("loading", false);
        })
        .catch((err) => {
          this.setAuthMessage({ message: err.message, type: "error" });
          this.busy = false;
          this.successAuthModal = false;
          this.$emit("loading", false);
        });
    },
  },
};
</script>
