var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_vm._l(_vm.getAttachmentsList, function (item, index) {
    var _item$name, _item$name3;
    return [item.progress <= 99 ? _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "pa-2",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-row', [_c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12",
        "md": "3"
      }
    }, [_c('v-avatar', {
      attrs: {
        "height": "76",
        "tile": "",
        "width": "76"
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.thumb ? item.thumb : item.image,
        "contain": "",
        "height": "76",
        "width": "76"
      }
    })], 1)], 1), _c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12",
        "md": "9"
      }
    }, [item !== null && item !== void 0 && item.name ? _c('span', [(item === null || item === void 0 ? void 0 : (_item$name = item.name) === null || _item$name === void 0 ? void 0 : _item$name.length) > 20 ? _c('span', [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var _item$name2;
          var on = _ref.on;
          return [_c('span', _vm._g({}, on), [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$name2 = item.name) === null || _item$name2 === void 0 ? void 0 : _item$name2.substring(0, 20)) + "...") + " ")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])])], 1) : _c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])]) : _c('span', [_vm._v("-")]), _c('div', [_c('b', [_vm._v(" By: ")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.user) + " ")]), _c('div', {}, [_c('b', [_vm._v(" size: ")]), _vm._v(" " + _vm._s(_vm.formatBytes(item.uploadingSize / 1000)) + "/" + _vm._s(_vm.formatBytes(item.size / 1000)) + " ")])]), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(item.progress + "%") + " ")]), _c('v-col', {
      staticClass: "px-0",
      attrs: {
        "cols": "7",
        "md": "8"
      }
    }, [_c('div', [_c('v-progress-linear', {
      attrs: {
        "color": item.progress >= 100 ? 'success' : 'primary',
        "value": item.progress,
        "height": "12"
      }
    })], 1)]), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "2"
      }
    }, [_c('v-col', {
      attrs: {
        "auto": ""
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.openFeedbackPage(item.appID, item.commentID, item.releaseID);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "primary"
      },
      domProps: {
        "textContent": _vm._s('mdi-arrow-right-circle-outline')
      }
    })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), item.progress > 99 ? _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "pa-2",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-row', [_c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12",
        "md": "3"
      }
    }, [_c('v-avatar', {
      attrs: {
        "height": "76",
        "tile": "",
        "width": "76"
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.thumb ? item.thumb : item.image,
        "contain": "",
        "height": "76",
        "width": "76"
      }
    })], 1)], 1), _c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12",
        "md": "9"
      }
    }, [_c('div', [_c('b', [_vm._v(" Name: ")]), item !== null && item !== void 0 && item.name ? _c('span', [(item === null || item === void 0 ? void 0 : (_item$name3 = item.name) === null || _item$name3 === void 0 ? void 0 : _item$name3.length) > 20 ? _c('span', [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var _item$name4;
          var on = _ref2.on;
          return [_c('span', _vm._g({}, on), [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$name4 = item.name) === null || _item$name4 === void 0 ? void 0 : _item$name4.substring(0, 20)) + "...") + " ")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])])], 1) : _c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])]) : _c('span', [_vm._v("-")])]), _c('div', [_c('b', [_vm._v(" By: ")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.user) + " ")]), _c('div', {}, [_c('b', [_vm._v(" size: ")]), _vm._v(" " + _vm._s(_vm.formatBytes(item.uploadingSize / 1000)) + "/" + _vm._s(_vm.formatBytes(item.size / 1000)) + " ")])]), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(item.progress + "%") + " ")]), _c('v-col', {
      staticClass: "px-0",
      attrs: {
        "cols": "7",
        "md": "8"
      }
    }, [_c('div', [_c('v-progress-linear', {
      attrs: {
        "color": item.progress >= 100 ? 'success' : 'primary',
        "value": item.progress,
        "height": "12"
      }
    })], 1)]), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "2"
      }
    }, [_c('v-col', {
      attrs: {
        "auto": ""
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.openFeedbackPage(item.appID, item.commentID, item.releaseID);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "primary"
      },
      domProps: {
        "textContent": _vm._s('mdi-arrow-right-circle-outline')
      }
    })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()];
  }), _vm._l(_vm.releaseUploadingList, function (item, index) {
    var _item$uploadDetail, _item$name5, _item$uploadDetail2, _item$name7, _item$uploadDetail4;
    return [item.progress < 100 ? _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "pa-2",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-row', [_c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12",
        "md": "3"
      }
    }, [_c('v-avatar', {
      attrs: {
        "height": "76",
        "tile": "",
        "width": "76"
      }
    }, [_c('v-img', {
      attrs: {
        "src": (item === null || item === void 0 ? void 0 : (_item$uploadDetail = item.uploadDetail) === null || _item$uploadDetail === void 0 ? void 0 : _item$uploadDetail.icon) || '/media/defaults/icon-120.png',
        "contain": "",
        "height": "76",
        "width": "76"
      }
    })], 1)], 1), _c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12",
        "md": "9"
      }
    }, [_c('div', [_c('b', [_vm._v(" Name: ")]), item !== null && item !== void 0 && item.name ? _c('span', [(item === null || item === void 0 ? void 0 : (_item$name5 = item.name) === null || _item$name5 === void 0 ? void 0 : _item$name5.length) > 20 ? _c('span', [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref3) {
          var _item$name6;
          var on = _ref3.on;
          return [_c('span', _vm._g({}, on), [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$name6 = item.name) === null || _item$name6 === void 0 ? void 0 : _item$name6.substring(0, 20)) + "...") + " ")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])])], 1) : _c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])]) : _c('span', [_vm._v("-")])]), _c('div', [_c('b', [_vm._v(" By: ")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$uploadDetail2 = item.uploadDetail) === null || _item$uploadDetail2 === void 0 ? void 0 : _item$uploadDetail2.user) + " ")]), _c('div', {}, [_c('b', [_vm._v(" size: ")]), _vm._v(" " + _vm._s(_vm.formatBytes(item.size / 1000)) + " ")]), _c('div', {}, [_c('b', [_vm._v(" version: ")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.uploadDetail.version) + " ")])]), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(item.progress + "%") + " ")]), _c('v-col', {
      staticClass: "px-0",
      attrs: {
        "cols": "7",
        "md": "8"
      }
    }, [_c('div', [_c('v-progress-linear', {
      attrs: {
        "color": item.progress >= 100 ? 'success' : 'primary',
        "value": item.progress,
        "height": "12"
      }
    })], 1)]), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "2"
      }
    }, [_c('v-col', {
      attrs: {
        "auto": ""
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          var _item$uploadDetail3;
          return _vm.openReleasePage(item.uploadDetail.appId, item === null || item === void 0 ? void 0 : (_item$uploadDetail3 = item.uploadDetail) === null || _item$uploadDetail3 === void 0 ? void 0 : _item$uploadDetail3.releaseId);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "primary"
      },
      domProps: {
        "textContent": _vm._s('mdi-arrow-right-circle-outline')
      }
    })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), item.progress >= 100 ? _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "pa-2",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-row', [_c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12",
        "md": "3"
      }
    }, [_c('v-avatar', {
      attrs: {
        "height": "76",
        "tile": "",
        "width": "76"
      }
    }, [item.type === 'android' ? _c('v-img', {
      attrs: {
        "src": (item === null || item === void 0 ? void 0 : item.uploadDetail.icon) || '/media/defaults/icon-120.png',
        "contain": "",
        "height": "76",
        "width": "76"
      }
    }) : _vm._e(), item.type === 'ios' ? _c('v-img', {
      attrs: {
        "src": (item === null || item === void 0 ? void 0 : item.uploadDetail.icon) || '/media/defaults/icon-120.png',
        "contain": "",
        "height": "76",
        "width": "76"
      }
    }) : _vm._e()], 1)], 1), _c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12",
        "md": "9"
      }
    }, [_c('div', [_c('b', [_vm._v(" Name: ")]), item !== null && item !== void 0 && item.name ? _c('span', [(item === null || item === void 0 ? void 0 : (_item$name7 = item.name) === null || _item$name7 === void 0 ? void 0 : _item$name7.length) > 20 ? _c('span', [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref4) {
          var _item$name8;
          var on = _ref4.on;
          return [_c('span', _vm._g({}, on), [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$name8 = item.name) === null || _item$name8 === void 0 ? void 0 : _item$name8.substring(0, 20)) + "...") + " ")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])])], 1) : _c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])]) : _c('span', [_vm._v("-")])]), _c('div', [_c('b', [_vm._v(" By: ")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$uploadDetail4 = item.uploadDetail) === null || _item$uploadDetail4 === void 0 ? void 0 : _item$uploadDetail4.user) + " ")]), _c('div', {}, [_c('b', [_vm._v(" size: ")]), _vm._v(" " + _vm._s(_vm.formatBytes(item.size / 1000)) + " ")]), _c('div', {}, [_c('b', [_vm._v(" version: ")]), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.uploadDetail.version) + " ")])]), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(item.progress + "%") + " ")]), _c('v-col', {
      staticClass: "px-0",
      attrs: {
        "cols": 7,
        "md": "8"
      }
    }, [_c('div', [_c('v-progress-linear', {
      attrs: {
        "color": item.progress >= 100 ? 'success' : 'primary',
        "value": item.progress,
        "height": "12"
      }
    })], 1)]), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "2"
      }
    }, [_c('v-col', {
      attrs: {
        "auto": ""
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          var _item$uploadDetail5;
          return _vm.openReleasePage(item.uploadDetail.appId, item === null || item === void 0 ? void 0 : (_item$uploadDetail5 = item.uploadDetail) === null || _item$uploadDetail5 === void 0 ? void 0 : _item$uploadDetail5.releaseId);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "primary"
      },
      domProps: {
        "textContent": _vm._s('mdi-arrow-right-circle-outline')
      }
    })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()];
  }), !_vm.getAttachmentsList.length && !_vm.releaseUploadingList.length ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('inline-svg', {
    staticClass: "mt-6 svg-icon empty_icon",
    attrs: {
      "src": "/media/error/empty-upload-queue.svg",
      "max-height": "250"
    }
  })], 1) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }