var render = function render(){
  var _vm$appInfo, _vm$appInfo2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showAppMenu && !_vm.getInfoErrorMessage ? _c('v-navigation-drawer', {
    class: _vm.$vuetify.theme.dark ? 'navigationDark' : 'navigationFont',
    attrs: {
      "id": "navigationDrawer",
      "expand-on-hover": _vm.$vuetify.breakpoint.mdAndUp,
      "mini-variant": _vm.$vuetify.breakpoint.mdAndUp,
      "app": "",
      "clipped": "",
      "height": "100%"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_vm.canManageTeam ? _c('v-list-item', {
          staticClass: "darkGrey--text",
          on: {
            "click": _vm.showUpgrade
          }
        }, [_c('v-list-item-icon', {
          staticClass: "pr-0"
        }, [_c('v-icon', {
          attrs: {
            "color": "darkGrey"
          }
        }, [_vm._v(" mdi-rocket-launch")])], 1), _c('v-list-item-title', [_c('span', {
          staticClass: "darkGrey--text bodyFont"
        }, [_vm._v(" " + _vm._s(_vm.getCurrentPlanName) + " ")])]), _c('v-list-item-action', {
          staticClass: "pr-8"
        }, [_c('div', {
          staticClass: "pt-1"
        }, [_vm.getCurrentPlanName !== 'Pro' && !(_vm.showStartTrialAlert || _vm.showActiveTrialAlert || _vm.showTrialEnded) ? _c('v-chip', {
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" Upgrade ")]) : _vm._e(), _vm.showStartTrialAlert || _vm.showTrialEnded ? _c('v-chip', {
          attrs: {
            "color": "error",
            "small": ""
          }
        }, [_vm._v(" Start Trial")]) : _vm._e(), _vm.showActiveTrialAlert ? _c('v-chip', {
          attrs: {
            "color": "error",
            "small": ""
          }
        }, [_vm._v(" Trial")]) : _vm._e()], 1)])], 1) : _vm._e(), _c('v-list-item'), _vm.$vuetify.breakpoint.smAndDown ? _c('v-list-item', {
          on: {
            "click": _vm.openWhatsNewList
          }
        }, [!_vm.showWhatsNewBadge ? _c('v-badge', {
          staticStyle: {
            "z-index": "1"
          },
          attrs: {
            "color": "success",
            "dot": ""
          }
        }, [_c('v-icon', {
          staticClass: "mr-9",
          attrs: {
            "color": "darkGrey",
            "small": ""
          }
        }, [_vm._v("mdi-newspaper ")]), _c('span', {
          staticClass: "darkGrey--text bodyFont"
        }, [_vm._v("What's New")])], 1) : [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "color": "darkGrey",
            "small": ""
          }
        }, [_vm._v("mdi-newspaper")])], 1), _c('v-list-item-title', [_c('span', {
          staticClass: "darkGrey--text bodyFont"
        }, [_vm._v("What's New")])])]], 2) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 2084970845),
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showAppMenu,
      expression: "!showAppMenu"
    }],
    class: _vm.$vuetify.breakpoint.mdAndUp ? 'mt-15 pt-5' : '',
    attrs: {
      "dense": "",
      "nav": ""
    }
  }, [_c('v-list', [_c('v-list-item', {
    staticClass: "pb-0 pt-0",
    attrs: {
      "id": "teamSwitchBtn"
    },
    on: {
      "click": function click($event) {
        _vm.teamActionModal = true;
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', {
    attrs: {
      "color": "darkGrey",
      "small": ""
    }
  }, [_vm._v("mdi-account-group")])], 1), _c('v-list-item-title', [_c('div', {
    staticClass: "bodyFont darkGrey--text",
    attrs: {
      "id": "teamName"
    }
  }, [_vm._v(" " + _vm._s(_vm.getTeamName) + " ")])]), _c('v-list-item-action', [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-chevron-right')
    }
  })], 1)], 1)], 1), _vm._l(_vm.standardMenuItems, function (item) {
    return [!item.children ? _c('v-list-item', {
      key: item.title,
      class: !_vm.helpers.isEmpty(_vm.getBroadCastMessage) && item.title == 'Dashboard' ? 'mt-7' : '',
      attrs: {
        "id": "item.title",
        "to": item.to,
        "link": ""
      },
      model: {
        value: item.active,
        callback: function callback($$v) {
          _vm.$set(item, "active", $$v);
        },
        expression: "item.active"
      }
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "color": "darkGrey",
        "small": ""
      }
    }, [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-title', [_c('div', {
      staticClass: "darkGrey--text font14"
    }, [_vm._v(_vm._s(item.title))])])], 1) : _vm._e(), item.children && item.show ? _c('v-list-group', {
      key: item.title,
      class: _vm.$vuetify.theme.dark ? 'navigationDark' : 'navigationFont',
      attrs: {
        "id": "teamPageList",
        "prepend-icon": item.action,
        "no-action": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn() {
          return [_c('v-list-item', {
            staticClass: "px-0",
            attrs: {
              "id": "teamPageList"
            }
          }, [_c('v-list-item-icon', [_c('v-icon', {
            attrs: {
              "color": "darkGrey",
              "small": ""
            }
          }, [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-title', [_c('span', {
            staticClass: "darkGrey--text py-2 bodyFont"
          }, [_vm._v(_vm._s(item.title))])])], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: item.active,
        callback: function callback($$v) {
          _vm.$set(item, "active", $$v);
        },
        expression: "item.active"
      }
    }, [_vm._l(item.children, function (child) {
      return [child.show ? _c('v-list-item', {
        key: child.title,
        staticClass: "px-10",
        attrs: {
          "id": child.title,
          "to": child.to,
          "link": ""
        }
      }, [_c('v-list-item-title', [_c('div', {
        staticClass: "darkGrey--text font14 py-2"
      }, [_vm._v(" " + _vm._s(child.title) + " ")])])], 1) : _vm._e()];
    })], 2) : _vm._e()];
  }), _vm.$vuetify.breakpoint.smAndDown ? _c('v-list-item', {
    attrs: {
      "link": "",
      "to": "/notifications"
    }
  }, [_vm.totalNotifications ? _c('v-badge', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "content": "".concat(_vm.totalNotifications).includes('-') ? '0' : _vm.totalNotifications,
      "color": "success"
    }
  }, [_c('v-icon', {
    staticClass: "mr-9",
    attrs: {
      "color": "darkGrey",
      "small": ""
    }
  }, [_vm._v("mdi-bell")]), _c('span', {
    staticClass: "bodyFont"
  }, [_vm._v("Notifications")])], 1) : [_c('v-list-item-icon', [_c('v-icon', {
    attrs: {
      "color": "darkGrey",
      "small": ""
    }
  }, [_vm._v("mdi-bell")])], 1), _c('v-list-item-title', [_c('div', {
    staticClass: "bodyFont"
  }, [_vm._v("Notifications")])])]], 2) : _vm._e()], 2), _vm.showAppMenu && !_vm.getInfoErrorMessage ? _c('v-list', {
    class: _vm.$vuetify.breakpoint.mdAndUp ? 'mt-15' : '',
    attrs: {
      "dense": "",
      "nav": ""
    }
  }, [_c('v-list-item', {
    class: !_vm.helpers.isEmpty(_vm.getBroadCastMessage) ? 'mt-8' : '',
    attrs: {
      "to": _vm.pageRoutes.app.list,
      "link": ""
    }
  }, [_c('v-list-item-icon', [_c('v-icon', {
    attrs: {
      "color": "darkGrey"
    }
  }, [_vm._v("mdi-chevron-left")])], 1), _c('v-list-item-title', [_c('div', {
    staticClass: "py-2 bodyFont darkGrey--text"
  }, [_vm._v("All apps")])])], 1), _c('v-list-item', {
    staticClass: "px-0",
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'app-settings',
          params: {
            app_id: _vm.$route.params.app_id
          }
        });
      }
    }
  }, [_c('v-list-item-avatar', [_c('v-img', {
    attrs: {
      "src": ((_vm$appInfo = _vm.appInfo) === null || _vm$appInfo === void 0 ? void 0 : _vm$appInfo.image) || "/media/defaults/".concat(_vm.$vuetify.theme.dark ? 'app_default_light' : 'app_default', ".svg"),
      "contain": "",
      "height": "30",
      "width": "30"
    }
  })], 1), _c('v-list-item-title', [_c('span', {
    staticClass: "bodyFont ml-3 mt-2 darkGrey--text",
    attrs: {
      "id": "appInfoName"
    }
  }, [_vm._v(_vm._s((_vm$appInfo2 = _vm.appInfo) === null || _vm$appInfo2 === void 0 ? void 0 : _vm$appInfo2.name))])])], 1), _vm._l(_vm.menuList, function (item, cindex) {
    var _item$badge, _item$badge2;
    return [item.show && !item.children ? _c('v-list-item', {
      key: cindex,
      class: _vm.$vuetify.theme.dark ? 'navigationDark' : 'navigationFont',
      attrs: {
        "id": item.title,
        "to": "/app/".concat(_vm.$route.params.app_id, "/").concat(item.link),
        "active-class": "act"
      }
    }, [_c('v-list-item-icon', [_c('v-icon', {
      staticClass: "darkGrey--text",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(item.icon) + " ")])], 1), _c('v-list-item-title', [_c('div', {
      staticClass: "py-2 bodyFont darkGrey--text 1"
    }, [_vm._v(" " + _vm._s(item.title) + " "), item.badge ? _c('v-chip', {
      staticClass: "py-2",
      attrs: {
        "color": item === null || item === void 0 ? void 0 : (_item$badge = item.badge) === null || _item$badge === void 0 ? void 0 : _item$badge.color,
        "label": "",
        "outlined": "",
        "x-small": ""
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$badge2 = item.badge) === null || _item$badge2 === void 0 ? void 0 : _item$badge2.label) + " ")]) : _vm._e()], 1)])], 1) : _vm._e(), item.children && item.show ? _c('v-list-group', {
      key: item.title,
      class: _vm.$vuetify.theme.dark ? 'navigationDark' : 'navigationFont',
      attrs: {
        "id": "teamPageList",
        "prepend-icon": item.action,
        "no-action": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn() {
          return [_c('v-list-item', {
            staticClass: "px-0",
            attrs: {
              "id": "teamPageList"
            }
          }, [_c('v-list-item-icon', [_c('v-icon', {
            attrs: {
              "color": "darkGrey",
              "small": ""
            }
          }, [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-title', [_c('span', {
            staticClass: "darkGrey--text py-2 bodyFont"
          }, [_vm._v(_vm._s(item.title))])])], 1)];
        },
        proxy: true
      }], null, true),
      model: {
        value: item.active,
        callback: function callback($$v) {
          _vm.$set(item, "active", $$v);
        },
        expression: "item.active"
      }
    }, [_vm._l(item.children, function (child) {
      return [child.show ? _c('v-list-item', {
        key: child.title,
        staticClass: "px-10",
        attrs: {
          "id": child.title,
          "to": "/app/".concat(_vm.$route.params.app_id, "/").concat(child.link),
          "exact": ""
        }
      }, [_c('v-list-item-icon', [_c('v-icon', {
        attrs: {
          "color": "darkGrey",
          "small": ""
        }
      }, [_vm._v(" " + _vm._s(child.icon) + " ")])], 1), _c('v-list-item-title', [_c('div', {
        staticClass: "darkGrey--text font14 py-2"
      }, [_vm._v(" " + _vm._s(child.title) + " ")])])], 1) : _vm._e()];
    })], 2) : _vm._e()];
  })], 2) : _vm._e()], 1) : _vm._e(), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 984 : '',
      "no-py": ""
    },
    on: {
      "close": function close($event) {
        _vm.openTrialModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('start-trial-modal', {
          attrs: {
            "from-menu": ""
          },
          on: {
            "close": function close($event) {
              _vm.openTrialModal = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openTrialModal,
      callback: function callback($$v) {
        _vm.openTrialModal = $$v;
      },
      expression: "openTrialModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": "800"
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.teamActionModal ? _c('v-card', {
          attrs: {
            "id": _vm.teamActionModal
          }
        }, [_c('v-card-title', [_c('div', {
          staticClass: "subListFont"
        }, [_vm._v("Team Actions")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.teamActionModal = false, _vm.selectedAction = 1;
            }
          }
        }, [_c('v-icon', {
          domProps: {
            "textContent": _vm._s('mdi-close')
          }
        })], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, _vm._l(_vm.teamActions, function (item, index) {
          return _c('v-list-item', {
            key: index,
            staticClass: "py-1",
            class: _vm.selectedAction === item.value ? 'primary white--text' : '',
            on: {
              "click": function click($event) {
                _vm.selectedAction = item.value;
              }
            }
          }, [_c('div', {
            staticClass: "pointer bodyFont",
            class: _vm.selectedAction === item.value ? 'primary white--text' : ''
          }, [_c('v-icon', {
            attrs: {
              "color": _vm.selectedAction === item.value ? 'white ' : '',
              "left": "",
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(item.icon) + " ")]), _vm._v(" " + _vm._s(item.name) + " ")], 1)]);
        }), 1)], 1), _c('v-col', [_vm.selectedAction === 1 ? _c('team-list', {
          attrs: {
            "from-menu": ""
          }
        }) : _vm._e(), _vm.selectedAction === 3 ? _c('update-team-info', {
          on: {
            "close": function close($event) {
              _vm.teamActionModal = false;
            }
          }
        }) : _vm._e(), _vm.selectedAction === 2 ? _c('create-team', {
          attrs: {
            "from-switch-team": ""
          },
          on: {
            "teamCreated": _vm.afterSuccess
          }
        }) : _vm._e(), _vm.selectedAction === 4 ? _c('leave-team-modal', {
          on: {
            "close": _vm.onCancel
          }
        }) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.teamActionModal,
      callback: function callback($$v) {
        _vm.teamActionModal = $$v;
      },
      expression: "teamActionModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": _vm.$vuetify.breakpoint.smAndDown ? '' : 600,
      "noPy": ""
    },
    on: {
      "close": _vm.closeFeedbackForm
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('feedback-form', {
          attrs: {
            "flat": ""
          },
          on: {
            "close": function close($event) {
              _vm.showFeedbackForm = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showFeedbackForm,
      callback: function callback($$v) {
        _vm.showFeedbackForm = $$v;
      },
      expression: "showFeedbackForm"
    }
  }), _c('SidePanel', {
    attrs: {
      "width": "700"
    },
    on: {
      "close": _vm.closeReleaseModal
    },
    model: {
      value: _vm.openRelease,
      callback: function callback($$v) {
        _vm.openRelease = $$v;
      },
      expression: "openRelease"
    }
  }, [_vm.openRelease ? _c('create-release', {
    attrs: {
      "app-info": _vm.appInfo,
      "from-create-panel": ""
    },
    on: {
      "close": _vm.closeReleaseModal
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }