<template>
  <v-theme-provider root>
    <v-app>
      <Loader v-if="loaderEnabled" :logo="loaderLogo" />
      <Header />
      <v-main :class="$vuetify.theme.dark ? 'darkClass' : 'lightClass'">
        <v-main
          :class="{
            'pt-10': !isEmpty(getBroadCastMessage),
            'mx-5': $vuetify.breakpoint.smAndDown,
            'mx-15': $vuetify.breakpoint.mdAndUp,
          }"
          class="mt-15 px-0"
        >
          <router-view />
        </v-main>
        <div class="mt-15 pt-15"></div>
      </v-main>
      <vue-snack-bar />
    </v-app>
  </v-theme-provider>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/view/layout/header/Header.vue";
import { isEmpty } from "@/core/services/helper.service";
import Loader from "@/view/components/Common/VueLoader.vue";

export default {
  name: "Layout",
  components: {
    Header,
    Loader,
  },
  data() {
    return { isEmpty, loaderEnabled: true };
  },
  mounted() {
    setTimeout(() => {
      this.loaderEnabled = false;
    }, 2500);
  },
  computed: {
    ...mapGetters({
      getDarkTheme: "getDarkTheme",
      getBroadCastMessage: "getBroadCastMessage",
    }),
    ...mapGetters(["isAuthenticated"]),
    loaderLogo() {
      return "/media/logos/logo_dark.png";
    },
    hidePadding() {
      return [
        "app-dashboard",
        "app-releases",
        "app-members",
        "app-share-link",
        "app-share-settings",
        "app-chat",
        "app-feedback",
        "app-subscribers",
        "app-timeline",
        "app-installs",
        "started-invite",
        "started-releases",
        "started-app",
        "started-team",
      ].includes(this.$route.name);
    },
  },
};
</script>
<style lang="scss" scoped>
.lightClass {
  background-color: $light-background;
  max-height: 100%;
}
.darkClass {
  background-color: #22272e;
  max-height: 100%;
}

#hubspot-messages-iframe-container {
  z-index: 999999 !important;
}
</style>