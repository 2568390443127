<template>
  <v-card flat>
    <v-row align="center" class="mx-4">
      <v-col cols="auto">
        <v-progress-circular :size="80" :value="8" :width="10" color="success">
          <div>
            {{ 8 }}
          </div>
        </v-progress-circular>
      </v-col>
      <v-col>
        <div class="font35">Quick start</div>
        <div class="mt-1 subListFont">
          Take full advantage of TestApp.io powerful app sharing features.
        </div>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row>
        <v-col class="font11 primary--text pb-0" cols="12"> THE BASIC </v-col>
        <v-col v-for="(item, findex) in pendingList" :key="findex" cols="12">
          <v-card class="pa-3" outlined>
            <v-row>
              <v-col class="subListFont bold-text">
                {{ item.title }}
              </v-col>
              <v-col class="pt-0 font12" cols="12">
                {{ item.description }}
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :outlined="$vuetify.theme.dark"
                class="text-transform-none"
                color="primary"
                depressed
                @click="goToCheckListPage(item.link, item.name)"
              >
                <div class="font12">Start</div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="font11 primary--text pb-0" cols="12"> LEVEL UP </v-col>
        <v-col v-for="(item, index) in levelUpList" :key="index" cols="12">
          <v-card class="pa-3" outlined>
            <v-row class="darkGrey--text">
              <v-col class="bodyFont bold-text">
                {{ item.title }}
              </v-col>
              <v-col class="pt-0 bodyFont" cols="12">
                {{ item.description }}
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :outlined="$vuetify.theme.dark"
                class="text-transform-none"
                color="primary"
                depressed
                >Start</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="font11 primary--text pb-0" cols="12"> COMPLETED </v-col>
        <v-col v-for="(item, index) in completedList" :key="index" cols="12">
          <v-card class="pa-3" outlined>
            <v-row class="darkGrey--text">
              <v-col class="subListFont bold-text">
                <v-icon
                  color="success"
                  left
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
                {{ item.title }}
              </v-col>
              <v-col class="text-right bodyFont" cols="6">
                {{ item.date }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      checklistList: [
        {
          name: "invite",
          title: "Invite your first member",
          status: 0,
          link: "invites",
          description:
            "Time to test it out. Now that you've created a project, capture your first error.\n" +
            "                We've got an example you can fiddle with.",
        },
        {
          name: "integrations",
          title: "Connect to your channel",
          link: "integrations",
          status: 0,
          description: "Time to connect to your slack or teams channel.",
        },
        {
          name: "release",
          title: "Create your first release",
          status: 0,
          link: "apps",
          description: "Time to create your first release.",
        },
        {
          name: "custom_install",
          title: "Customize your install page",
          status: 2,
          description: "Time to customize your install page.",
        },
        {
          name: "app",
          title: "Create your first app",
          status: 1,
          date: "2 days ago",
          description: "Time to create your first app.",
        },
      ],
    };
  },
  computed: {
    pendingList() {
      return this.checklistList.filter((item) => item.status === 0);
    },
    levelUpList() {
      return this.checklistList.filter((item) => item.status === 2);
    },
    completedList() {
      return this.checklistList.filter((item) => item.status === 1);
    },
  },
  methods: {
    goToCheckListPage(link, name) {
      this.$router.push({ name: link });
      this.checklistList = this.checklistList.map((item) => {
        if (item.name === name) {
          item.status = 1;
        }
        return item;
      });
    },
  },
};
</script>
