<template>
  <div>
    <v-card tile outlined>
      <v-card-title>
        <div>
          <v-chip
            class="mr-2 white--text"
            small
            :color="$options.tagColor[detail.tag]"
          >
            {{ detail.tag }}
          </v-chip>
          <span class="text-caption bold-text">
            <vue-hoverable-date :date="detail.created_at"></vue-hoverable-date>
          </span>
          <div
            style="white-space: pre-wrap"
            class="mt-3 font-size-h2 bold-text"
          >
            {{ detail.title }}
          </div>
        </div>
      </v-card-title>
      <v-card-text class="pb-8">
        <div class="bodyFont" v-html="detail.content"></div>
      </v-card-text>
    </v-card>
    <v-row class="px-3 lightBackground" no-gutters>
      <v-col class="pb-0 mt-n8 pt-4 text-center" cols="12">
        <span class="mx-1" v-for="(emoji, eindex) in emojiRating" :key="eindex">
          <v-hover v-slot="{ hover }">
            <v-btn small @click.stop="sendRate(emoji.value)" icon>
              <v-img
                :src="emoji.icon"
                color="warning"
                contain
                :class="rate === emoji.value || hover ? '' : 'greyscale'"
                width="40"
                :height="rate === emoji.value || hover ? '30' : '25'"
              ></v-img>
            </v-btn>
          </v-hover>
        </span>
      </v-col>
      <v-col class="mt-2 mb-4" cols="12">
        <div v-if="showSuccess">
          <v-icon v-text="'mdi-check-circle'" color="success" left></v-icon>
          Thank you for your feedback!
        </div>
        <v-text-field
          v-else
          outlined
          dense
          @keypress.enter="postWhatsNewFeedback()"
          :append-icon="message ? 'mdi-send' : ''"
          @click:append="postWhatsNewFeedback()"
          v-model="message"
          placeholder="Feedback"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { POST_WHATS_NEW_FEEDBACK } from "@/store/users/whatsNew.module";

export default {
  props: {
    detail: {
      type: Object,
      required: true,
    },
  },
  tagColor: {
    new: "pink",
    improvement: "orange",
    annoucement: "success",
    news: "primary",
  },
  data() {
    return {
      message: "",
      rate: null,
      showSuccess: false,
      emojiRating: [
        {
          icon: "/media/emoji/in-love.png",
          value: 2,
        },
        {
          icon: "/media/emoji/happy.png",
          value: 1,
        },
        {
          icon: "/media/emoji/sad.png",
          value: 3,
        },
      ],
    };
  },
  created() {
    if (this.detail.rate) {
      this.rate = this.detail.rate;
    }
  },
  methods: {
    sendRate(value) {
      this.rate = value;
      this.detail.value = value;
      this.postWhatsNewFeedback("rating");
    },
    postWhatsNewFeedback(rating) {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(POST_WHATS_NEW_FEEDBACK, {
          id: this.detail.id,
          message: this.message || "",
          rate: this.rate,
        })
        .then(() => {
          if (this.message && !rating) {
            this.showSuccess = true;
          }
          if (!rating) {
            this.message = "";
          }

          this.busy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
  },
};
</script>

<style scoped>
.greyscale {
  filter: grayscale(100%);
}
</style>
