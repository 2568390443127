<template>
  <v-row>
    <template v-for="(item, index) in getAttachmentsList">
      <v-col v-if="item.progress <= 99" :key="index" cols="12">
        <v-card class="pa-2" outlined>
          <v-row>
            <v-col class="pb-0" cols="12" md="3">
              <v-avatar height="76" tile width="76">
                <v-img
                  :src="item.thumb ? item.thumb : item.image"
                  contain
                  height="76"
                  width="76"
                ></v-img>
              </v-avatar>
            </v-col>
            <v-col class="pb-0" cols="12" md="9">
              <span v-if="item?.name">
                <span v-if="item?.name?.length > 20">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span class v-on="on">
                        {{ item?.name?.substring(0, 20) + "..." }}
                      </span>
                    </template>
                    <span>
                      {{ item?.name }}
                    </span>
                  </v-tooltip>
                </span>
                <span v-else>
                  {{ item?.name }}
                </span>
              </span>
              <span v-else>-</span>
              <div>
                <b> By: </b>
                {{ item?.user }}
              </div>
              <div class="">
                <b> size: </b>
                {{ formatBytes(item.uploadingSize / 1000) }}/{{
                  formatBytes(item.size / 1000)
                }}
              </div>
            </v-col>
            <v-col class="py-0" cols="12">
              <v-row align="center">
                <v-col cols="auto">
                  {{ item.progress + "%" }}
                </v-col>
                <v-col class="px-0" cols="7" md="8">
                  <div>
                    <v-progress-linear
                      :color="item.progress >= 100 ? 'success' : 'primary'"
                      :value="item.progress"
                      height="12"
                    >
                    </v-progress-linear>
                  </div>
                </v-col>
                <v-col class="pl-0" cols="2">
                  <v-col auto class="">
                    <v-btn
                      icon
                      @click="
                        openFeedbackPage(
                          item.appID,
                          item.commentID,
                          item.releaseID
                        )
                      "
                    >
                      <v-icon
                        color="primary"
                        v-text="'mdi-arrow-right-circle-outline'"
                      ></v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col v-if="item.progress > 99" :key="index" cols="12">
        <v-card class="pa-2" outlined>
          <v-row>
            <v-col class="pb-0" cols="12" md="3">
              <v-avatar height="76" tile width="76">
                <v-img
                  :src="item.thumb ? item.thumb : item.image"
                  contain
                  height="76"
                  width="76"
                ></v-img>
              </v-avatar>
            </v-col>
            <v-col class="pb-0" cols="12" md="9">
              <div>
                <b> Name: </b>
                <span v-if="item?.name">
                  <span v-if="item?.name?.length > 20">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span class v-on="on">
                          {{ item?.name?.substring(0, 20) + "..." }}
                        </span>
                      </template>
                      <span>
                        {{ item?.name }}
                      </span>
                    </v-tooltip>
                  </span>
                  <span v-else>
                    {{ item?.name }}
                  </span>
                </span>
                <span v-else>-</span>
              </div>
              <div>
                <b> By: </b>
                {{ item?.user }}
              </div>
              <div class="">
                <b> size: </b>
                {{ formatBytes(item.uploadingSize / 1000) }}/{{
                  formatBytes(item.size / 1000)
                }}
              </div>
            </v-col>
            <v-col class="py-0" cols="12">
              <v-row align="center">
                <v-col cols="auto">
                  {{ item.progress + "%" }}
                </v-col>
                <v-col class="px-0" cols="7" md="8">
                  <div>
                    <v-progress-linear
                      :color="item.progress >= 100 ? 'success' : 'primary'"
                      :value="item.progress"
                      height="12"
                    >
                    </v-progress-linear>
                  </div>
                </v-col>
                <v-col class="pl-0" cols="2">
                  <v-col auto class="">
                    <v-btn
                      icon
                      @click="
                        openFeedbackPage(
                          item.appID,
                          item.commentID,
                          item.releaseID
                        )
                      "
                    >
                      <v-icon
                        color="primary"
                        v-text="'mdi-arrow-right-circle-outline'"
                      ></v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </template>
    <template v-for="(item, index) in releaseUploadingList">
      <v-col v-if="item.progress < 100" :key="index" cols="12">
        <v-card class="pa-2" outlined>
          <v-row>
            <v-col class="pb-0" cols="12" md="3">
              <v-avatar height="76" tile width="76">
                <v-img
                  :src="
                    item?.uploadDetail?.icon || '/media/defaults/icon-120.png'
                  "
                  contain
                  height="76"
                  width="76"
                ></v-img>
              </v-avatar>
            </v-col>
            <v-col class="pb-0" cols="12" md="9">
              <div>
                <b> Name: </b>
                <span v-if="item?.name">
                  <span v-if="item?.name?.length > 20">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span class v-on="on">
                          {{ item?.name?.substring(0, 20) + "..." }}
                        </span>
                      </template>
                      <span>
                        {{ item?.name }}
                      </span>
                    </v-tooltip>
                  </span>
                  <span v-else>
                    {{ item?.name }}
                  </span>
                </span>
                <span v-else>-</span>
              </div>
              <div>
                <b> By: </b>
                {{ item?.uploadDetail?.user }}
              </div>
              <div class="">
                <b> size: </b>
                {{ formatBytes(item.size / 1000) }}
              </div>
              <div class="">
                <b> version: </b>

                {{ item?.uploadDetail.version }}
              </div>
            </v-col>
            <v-col class="py-0" cols="12">
              <v-row align="center">
                <v-col cols="auto">
                  {{ item.progress + "%" }}
                </v-col>
                <v-col class="px-0" cols="7" md="8">
                  <div>
                    <v-progress-linear
                      :color="item.progress >= 100 ? 'success' : 'primary'"
                      :value="item.progress"
                      height="12"
                    >
                    </v-progress-linear>
                  </div>
                </v-col>
                <v-col class="pl-0" cols="2">
                  <v-col auto class="">
                    <v-btn
                      icon
                      @click="
                        openReleasePage(
                          item.uploadDetail.appId,
                          item?.uploadDetail?.releaseId
                        )
                      "
                    >
                      <v-icon
                        color="primary"
                        v-text="'mdi-arrow-right-circle-outline'"
                      ></v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col v-if="item.progress >= 100" :key="index" cols="12">
        <v-card class="pa-2" outlined>
          <v-row>
            <v-col class="pb-0" cols="12" md="3">
              <v-avatar height="76" tile width="76">
                <v-img
                  v-if="item.type === 'android'"
                  :src="
                    item?.uploadDetail.icon || '/media/defaults/icon-120.png'
                  "
                  contain
                  height="76"
                  width="76"
                ></v-img>
                <v-img
                  v-if="item.type === 'ios'"
                  :src="
                    item?.uploadDetail.icon || '/media/defaults/icon-120.png'
                  "
                  contain
                  height="76"
                  width="76"
                ></v-img>
              </v-avatar>
            </v-col>
            <v-col class="pb-0" cols="12" md="9">
              <div>
                <b> Name: </b>
                <span v-if="item?.name">
                  <span v-if="item?.name?.length > 20">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span class v-on="on">
                          {{ item?.name?.substring(0, 20) + "..." }}
                        </span>
                      </template>
                      <span>
                        {{ item?.name }}
                      </span>
                    </v-tooltip>
                  </span>
                  <span v-else>
                    {{ item?.name }}
                  </span>
                </span>
                <span v-else>-</span>
              </div>
              <div>
                <b> By: </b>
                {{ item?.uploadDetail?.user }}
              </div>
              <div class="">
                <b> size: </b>
                {{ formatBytes(item.size / 1000) }}
              </div>
              <div class="">
                <b> version: </b>

                {{ item?.uploadDetail.version }}
              </div>
            </v-col>
            <v-col class="py-0" cols="12">
              <v-row align="center">
                <v-col cols="auto">
                  {{ item.progress + "%" }}
                </v-col>
                <v-col :cols="7" class="px-0" md="8">
                  <div>
                    <v-progress-linear
                      :color="item.progress >= 100 ? 'success' : 'primary'"
                      :value="item.progress"
                      height="12"
                    >
                    </v-progress-linear>
                  </div>
                </v-col>
                <v-col class="pl-0" cols="2">
                  <v-col auto class="">
                    <v-btn
                      icon
                      @click="
                        openReleasePage(
                          item.uploadDetail.appId,
                          item?.uploadDetail?.releaseId
                        )
                      "
                    >
                      <v-icon
                        color="primary"
                        v-text="'mdi-arrow-right-circle-outline'"
                      ></v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </template>
    <v-col
      v-if="!getAttachmentsList.length && !releaseUploadingList.length"
      cols="12"
    >
      <inline-svg
        :src="`/media/error/empty-upload-queue.svg`"
        class="mt-6 svg-icon empty_icon"
        max-height="250"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { formatBytes } from "@/core/services/helper.service";
// import StorageService from "@/core/services/localstorage.service";

export default {
  name: "feedback-uploading-menu",
  data() {
    return {
      formatBytes,
    };
  },
  computed: {
    ...mapGetters({
      getTotalProgress: "getTotalProgress",
      getAttachmentsList: "getAttachmentsList",
      getIOSProgress: "getIOSProgress",
      getAndroidProgress: "getAndroidProgress",
      getApkFile: "getApkFile",
      getReleaseProgressList: "getReleaseProgressList",
      ipaFileInfo: "getIpaFile",
    }),
    // getAttachmentsList() {
    //   return StorageService.getItem('feedbackList')
    // },
    releaseUploadingList() {
      return this.getReleaseProgressList;
    },
  },
  methods: {
    ...mapMutations({
      setReleaseRefresh: "setReleaseRefresh",
    }),
    replaceDefaultAndroidImage(e, type) {
      if (type === "android") {
        e.target.src = "/media/defaults/icon-120.png";
      } else {
        e.target.src = "/media/defaults/icon-120.png";
      }
    },
    openFeedbackPage(appID, commentId, releaseId) {
      this.$router.push({
        name: "release-comment",
        params: {
          app_id: appID,
          release_id: releaseId,
          comment_id: commentId,
        },
      });
      this.$emit("close");
    },
    openReleasePage(appID, releaseId) {
      this.$router.push(`/app/${appID}/release/${releaseId}`);
      this.$emit("close");
    },
  },
};
</script>
