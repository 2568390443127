var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": _vm.noClose
    }
  }, [!_vm.noClose ? _c('v-card-title', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-close')
    }
  })], 1)], 1) : _vm._e(), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "text-center bodyFont mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Download our " + _vm._s(_vm.storeLink.platform == "ios" ? "iOS" : "Android") + " app "), !_vm.outside ? _c('div', [_vm._v(" Install releases, manage team, feedback, and more! ")]) : _vm._e()]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('figure', {
    staticClass: "qrcode"
  }, [_c('qrcode', {
    attrs: {
      "value": _vm.storeLink.url,
      "options": {
        width: 200
      }
    }
  }), _c('v-icon', {
    staticClass: "qrcode_image",
    attrs: {
      "color": "primary"
    },
    domProps: {
      "textContent": _vm._s(_vm.storeLink.platform === 'ios' ? 'mdi-apple' : 'mdi-android')
    }
  })], 1)]), _c('v-col', {
    staticClass: "text-center bodyFont",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Scan the above QR code from your phone to download our app "), _c('br'), _vm._v(" Or "), _c('a', {
    staticClass: "text-decoration-underline",
    attrs: {
      "href": _vm.storeLink.url,
      "target": "_blank"
    }
  }, [_vm._v("click here")]), _vm._v(" to open the store "), _vm.storeLink.platform == 'android' ? _c('div', {
    staticClass: "mt-4"
  }, [_vm._v(" We also have the option to download our app as "), _c('a', {
    staticClass: "text-decoration-underline",
    attrs: {
      "href": "https://github.com/testappio/android/releases",
      "target": "_blank"
    }
  }, [_vm._v("APK")]), _vm._v(" outside the store for special cases ")]) : _vm._e()])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }