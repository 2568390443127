var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', {
    staticClass: "mx-4",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "size": 80,
      "value": 8,
      "width": 10,
      "color": "success"
    }
  }, [_c('div', [_vm._v(" " + _vm._s(8) + " ")])])], 1), _c('v-col', [_c('div', {
    staticClass: "font35"
  }, [_vm._v("Quick start")]), _c('div', {
    staticClass: "mt-1 subListFont"
  }, [_vm._v(" Take full advantage of TestApp.io powerful app sharing features. ")])])], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "font11 primary--text pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" THE BASIC ")]), _vm._l(_vm.pendingList, function (item, findex) {
    return _c('v-col', {
      key: findex,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "pa-3",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-row', [_c('v-col', {
      staticClass: "subListFont bold-text"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-col', {
      staticClass: "pt-0 font12",
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")])], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      staticClass: "text-transform-none",
      attrs: {
        "outlined": _vm.$vuetify.theme.dark,
        "color": "primary",
        "depressed": ""
      },
      on: {
        "click": function click($event) {
          return _vm.goToCheckListPage(item.link, item.name);
        }
      }
    }, [_c('div', {
      staticClass: "font12"
    }, [_vm._v("Start")])])], 1)], 1)], 1);
  }), _c('v-col', {
    staticClass: "font11 primary--text pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" LEVEL UP ")]), _vm._l(_vm.levelUpList, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "pa-3",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-row', {
      staticClass: "darkGrey--text"
    }, [_c('v-col', {
      staticClass: "bodyFont bold-text"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-col', {
      staticClass: "pt-0 bodyFont",
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")])], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      staticClass: "text-transform-none",
      attrs: {
        "outlined": _vm.$vuetify.theme.dark,
        "color": "primary",
        "depressed": ""
      }
    }, [_vm._v("Start")])], 1)], 1)], 1);
  }), _c('v-col', {
    staticClass: "font11 primary--text pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" COMPLETED ")]), _vm._l(_vm.completedList, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "pa-3",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-row', {
      staticClass: "darkGrey--text"
    }, [_c('v-col', {
      staticClass: "subListFont bold-text"
    }, [_c('v-icon', {
      attrs: {
        "color": "success",
        "left": ""
      },
      domProps: {
        "textContent": _vm._s('mdi-check-circle-outline')
      }
    }), _vm._v(" " + _vm._s(item.title) + " ")], 1), _c('v-col', {
      staticClass: "text-right bodyFont",
      attrs: {
        "cols": "6"
      }
    }, [_vm._v(" " + _vm._s(item.date) + " ")])], 1)], 1)], 1);
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }