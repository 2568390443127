<template>
  <v-card flat class="pa-2">
    <v-card-title>
      <div>
        <div class="bold-text">Notification settings</div>
        <div class="text-muted bodyFont">
          We'll never spam you. We hate it too!
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <div v-if="loading">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </div>
      <div v-else>
        <v-row align="center">
          <v-col v-if="errorMessage" class="py-15" cols="12">
            <no-data
              with-button
              first-text="Ops!"
              :second-text="errorMessage"
            ></no-data>
          </v-col>
          <template v-if="!errorMessage">
            <v-col cols="8"> </v-col>
            <v-col class="bold-text" cols="2"> Email </v-col>
            <v-col class="bold-text" cols="2"> Push </v-col>
          </template>
        </v-row>
        <template v-if="!errorMessage">
          <v-row align="center" v-for="(item, index) in showItems" :key="index">
            <v-col class="font-size-h4" cols="8">
              <div class="bold-text pb-2">
                {{ settingsDesc[item] ? settingsDesc[item].title : "" }}
              </div>
              <div
                class="text-muted bodyFont"
                v-html="
                  settingsDesc[item] ? settingsDesc[item].description : ''
                "
              ></div>
            </v-col>
            <v-col cols="2">
              <v-checkbox
                :id="`emailSettingCheckbox${index}`"
                hide-details
                :disabled="item === 'essentials'"
                v-model="settings[item].email"
              ></v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-checkbox
                :id="`pushSettingCheckbox${index}`"
                hide-details
                :disabled="item === 'essentials'"
                v-model="settings[item].push"
              ></v-checkbox>
            </v-col>
          </v-row>
        </template>
        <v-row v-if="$route.query.action">
          <v-col class="text-center" cols="12">
            <v-btn
              color="primary"
              class="text-transform-none"
              text
              @click="showMore = !showMore"
            >
              {{ showMore ? "Show less" : "View all notifications" }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions v-if="!loading && !errorMessage">
      <v-spacer></v-spacer>
      <v-btn
        @click="updateSettings"
        depressed
        :loading="loading"
        :outlined="$vuetify.theme.dark"
        :disabled="loading || !!errorMessage"
        id="updateNotificationSettingBtn"
        color="primary"
        class="text-transform-none"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  GET_NOTIFICATIONS_SETTINGS,
  UPDATE_NOTIFICATIONS_SETTINGS,
} from "@/store/users/settings.module.js";
import { mapGetters, mapMutations } from "vuex";
import { isEmpty } from "@/core/services/helper.service.js";
export default {
  props: {
    fromPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      updateUUID: "",
      attrs: {},
      showMore: false,
      individualSetting: [],
      settingsDesc: {
        product_news: {
          title: "Product news",
          description:
            "1-2 emails a month. New features, significant changes, and the latest information about TestApp.io",
        },
        promotions: {
          title: "Promotions",
          description:
            "(Team owners only) Offers, discounts, and updates about our plans",
        },
        recommendations: {
          title: "Recommendations",
          description:
            "Tips and tricks to get you going and leverage TestApp.io to the max",
        },
        reports: {
          title: "Reports",
          description:
            "(Team owners only) Weekly reports about your apps, releases, install, views, and similar essential metrics about your teams",
        },
        system: {
          title: "System",
          description:
            "(Team owners & developers only) Failed releases, expired public links, invites, and similar essential events",
        },
        surveys: {
          title: "Surveys",
          description:
            "Everyone 1-2 months, we will send one survey based on your usage to help us improve our product",
        },
        essentials: {
          title: "Essentials",
          description:
            "Essential services and functionality, including trial notices, identity verification, service continuity, and security. Opt-Out is not available",
        },
      },
      errorMessage: null,
      settings: {
        uuid: "",
        product_news: {
          email: true,
          push: false,
        },
        promotions: {
          email: false,
          push: false,
        },
        recommendations: {
          email: false,
          push: false,
        },
        reports: {
          email: true,
          push: true,
        },
        system: {
          email: true,
          push: true,
        },
        surveys: {
          email: true,
          push: true,
        },
        essentials: {
          email: true,
          push: true,
        },
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getNotificationsSetting: "getNotificationsSetting",
    }),
    settingList() {
      return Object.keys(this.settings).splice(1, 7);
    },
    showItems() {
      if (this.$route.query.action && !this.showMore) {
        return this.individualSetting;
      } else {
        return this.settingList;
      }
    },
  },
  watch: {
    getNotificationsSetting(val) {
      if (val) {
        val.essentials = {
          email: true,
          push: true,
        };
        this.settings = val;
      }
    },
  },
  mounted() {
    if (this.getNotificationsSetting && !this.fromPage) {
      this.settings = this.getNotificationsSetting;
      this.settings.essentials = {
        email: true,
        push: true,
      };
    }
    if (this.$route.query.action) {
      this.individualSetting = Object.keys(this.settings).filter(
        (x) => x === this.$route.query.action
      );
    }
    this.populateInitialData();
  },
  methods: {
    ...mapMutations({
      updateNotificationsSetting: "updateNotificationsSetting",
      setAuthMessage: "setAuthMessage",
    }),
    populateInitialData() {
      if (this.fromPage) {
        if (this.loading) return;
        this.loading = true;
        this.$nextTick(() => {
          if (isEmpty(this.$route.params)) {
            this.$router
              .push({
                name: "dashboard",
                query: { navigate_to: "notification_settings" },
              })
              .catch();
            this.loading = false;
            this.setAuthMessage({
              message: "Signin to update your notification settings",
              type: "error",
            });
          } else {
            this.loading = false;
            this.getSettingInfo();
          }
        });
      }
    },
    getSettingInfo() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_NOTIFICATIONS_SETTINGS, this.$route.params.uuid)
        .then(({ data }) => {
          this.loading = false;
          this.settings = { ...data.data.settings };
          this.settings.essentials = {
            email: true,
            push: true,
          };
        })
        .catch((err) => {
          this.errorMessage = err.message;
          this.loading = false;
        });
    },
    updateSettings() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(UPDATE_NOTIFICATIONS_SETTINGS, {
          settings: this.settings,
        })
        .then((response) => {
          this.loading = false;
          this.updateNotificationsSetting(this.settings);
          this.$emit("close");
          this.notifyUserMessage({
            message:
              response.message || "Successfully updated notification settings",
          });
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message || "Something went wrong");
        });
    },
  },
};
</script>
