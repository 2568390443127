<template>
  <v-card :outlined="noClose">
    <v-card-title v-if="!noClose">
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')" icon>
        <v-icon v-text="'mdi-close'"></v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="text-center bodyFont mt-4" cols="12">
          Download our
          {{ storeLink.platform == "ios" ? "iOS" : "Android" }} app
          <div v-if="!outside">
            Install releases, manage team, feedback, and more!
          </div>
        </v-col>
        <v-col cols="12" class="text-center">
          <figure class="qrcode">
            <qrcode :value="storeLink.url" :options="{ width: 200 }" />
            <v-icon
              class="qrcode_image"
              color="primary"
              v-text="
                storeLink.platform === 'ios' ? 'mdi-apple' : 'mdi-android'
              "
            />
          </figure>
        </v-col>
        <v-col cols="12" class="text-center bodyFont">
          Scan the above QR code from your phone to download our app <br />
          Or
          <a
            :href="storeLink.url"
            class="text-decoration-underline"
            target="_blank"
            >click here</a
          >
          to open the store

          <div v-if="storeLink.platform == 'android'" class="mt-4">
            We also have the option to download our app as
            <a
              href="https://github.com/testappio/android/releases"
              target="_blank"
              class="text-decoration-underline"
              >APK</a
            >
            outside the store for special cases
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    outside: {
      type: Boolean,
      default: false,
    },
    storeLink: {
      type: Object,
      required: true,
    },
    noClose: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.qrcode_image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 15%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15%;
}
</style>
