var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-theme-provider', {
    attrs: {
      "root": ""
    }
  }, [_c('v-app', [_vm.loaderEnabled ? _c('Loader', {
    attrs: {
      "logo": _vm.loaderLogo
    }
  }) : _vm._e(), _c('Header'), _c('v-main', {
    class: _vm.$vuetify.theme.dark ? 'darkClass' : 'lightClass'
  }, [_c('v-main', {
    staticClass: "mt-15 px-0",
    class: {
      'pt-10': !_vm.isEmpty(_vm.getBroadCastMessage),
      'mx-5': _vm.$vuetify.breakpoint.smAndDown,
      'mx-15': _vm.$vuetify.breakpoint.mdAndUp
    }
  }, [_c('router-view')], 1), _c('div', {
    staticClass: "mt-15 pt-15"
  })], 1), _c('vue-snack-bar')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }