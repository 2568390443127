export const nonMenuRoutes = [
  "welcome",
  "get-started",
  "started-team",
  "started-app",
  "download-app",
  "started-releases",
  "started-invite",
];

export const socialMediaInfo = [{
    link: "https://www.linkedin.com/company/testappio",
    name: "LinkedIn",
    icon: "mdi-linkedin",
  },
  {
    link: "https://twitter.com/TestAppio",
    name: "Twitter",
    icon: "mdi-twitter",
  },
  {
    link: "https://join.slack.com/t/testappio/shared_invite/zt-pvpoj3l2-epGYwGTaV3~3~0f7udNWoA",
    name: "Slack",
    icon: "mdi-slack",
  },
  {
    link: "https://roadmap.testapp.io/",
    name: "Request a Feature",
    icon: "mdi-star-circle-outline",
  },
];

export const teamActions = [{
    name: "Switch Teams",
    icon: "mdi-transit-transfer",
    value: 1,
  },
  {
    name: "Create Team",
    icon: "mdi-plus",
    value: 2,
  },
  {
    name: "Edit Team",
    icon: "edit",
    value: 3,
  },
  {
    name: "Leave Team",
    icon: "logout",
    value: 4,
  },
];
