<template>
  <v-card
    :flat="flat"
    :width="$vuetify.breakpoint.mdAndUp ? 600 : ''"
    class="pa-2"
  >
    <v-card-title class="pt-1">
      <div>
        <div class="bold-text font-size-h4">Send Feedback</div>
        <div class="font-size-h4">
          Help us improve our product, any feedback is welcome.
        </div>
      </div>
    </v-card-title>
    <v-card-text class="py-0">
      <v-row class="mx-0 px-0">
        <v-col class="py-0" cols="12">
          <v-textarea
            placeholder="Let us know what could be we doing better!"
            filled
            autofocus
            color="primary"
            v-model="feedback.message"
            v-bind="veeValidate('Message', '')"
            v-validate="'required|max:600'"
            counter="600"
          ></v-textarea>
        </v-col>
        <v-col
          class="py-0"
          cols="12"
          :class="{
            'text-center': $vuetify.breakpoint.smAndDown,
            'mx-3': $vuetify.breakpoint.mdAndUp,
            'pb-6': $vuetify.breakpoint.smAndDown,
            'pl-n10': $vuetify.breakpoint.smAndDown,
          }"
        >
          <span v-for="(item, index) in emojiRating" :key="index">
            <v-hover v-slot="{ hover }">
              <v-btn small @click.stop="setFeedbackRate(item.value)" icon>
                <v-img
                  :src="item.icon"
                  color="warning"
                  contain
                  width="40"
                  :height="feedback.rate === item.value || hover ? '30' : '25'"
                ></v-img>
              </v-btn>
            </v-hover>
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-row class="px-0 mx-0">
      <v-col class="pr-6 text-center" cols="12" md="12">
        <v-btn
          :class="{ 'mr-2': $vuetify.breakpoint.mdAndUp }"
          @click.stop="sendFeedback"
          depressed
          :loading="busy"
          class="text-capitalize"
          :outlined="$vuetify.theme.dark"
          color="primary"
          >Send Feedback</v-btn
        >
      </v-col>
    </v-row>
    <v-card-text>
      <v-row>
        <v-col class="text-center" cols="12">
          <v-col class="mx-auto py-0" cols="6">
            <v-divider></v-divider>
          </v-col>
        </v-col>
        <v-col class="py-0 text-center font-size-h4" cols="12">
          Reach anytime and everywhere
        </v-col>
        <v-col class="pb-0" cols="12">
          <v-row align="center" justify="space-between">
            <v-col
              :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
              cols="12"
              md="6"
            >
              <v-btn
                @click="openSchedule"
                color="primary"
                depressed
                :outlined="$vuetify.theme.dark"
                class="text-transform-none mx-auto"
              >
                <v-icon v-text="'mdi-phone'" left></v-icon>
                Schedule a call
              </v-btn>
            </v-col>
            <v-col class="text-center" cols="12" md="6">
              <v-tooltip
                v-for="(item, index) in socialLinks"
                :key="index"
                bottom
                color="primary"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    depressed
                    icon
                    v-on="on"
                    class="ma-1"
                    color="primary"
                    @click="openSocialLinks(item.link)"
                  >
                    <v-icon v-text="item.icon"></v-icon>
                  </v-btn>
                </template>
                <span>{{ item.title }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import { SEND_FEEDBACK } from "@/store/users/dashboard.module";
import { mapGetters } from "vuex";

export default {
  mixins: [veeValidate],
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emojiRating: [
        {
          icon: "/media/emoji/in-love.png",
          value: 2,
        },
        {
          icon: "/media/emoji/happy.png",
          value: 1,
        },
        {
          icon: "/media/emoji/sad.png",
          value: 3,
        },
      ],
      socialLinks: [
        {
          title: "Slack",
          link: "https://join.slack.com/t/testappio/shared_invite/zt-pvpoj3l2-epGYwGTaV3~3~0f7udNWoA",
          icon: "mdi-slack",
        },
        {
          title: "Linkedin",
          link: "https://www.linkedin.com/company/testappio",
          icon: "mdi-linkedin",
        },
      ],
      showFeedbackForm: false,
      busy: false,
      feedback: {
        message: "",
        rate: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      getFeedbackModalDetail: "getFeedbackModalDetail",
    }),
  },
  watch: {
    showFeedbackForm(val) {
      if (!val) {
        this.feedback = {
          message: "",
          rate: 0,
        };
      }
      this.busy = false;
      this.$validator.errors.clear();
    },
    getFeedbackModalDetail(val) {
      if (val) {
        this.feedback = {
          message: `Suggesting a missing integration:\n\n- Name:\n- Reason:`,
          rate: 0,
        };
      } else {
        this.feedback = {
          message: "",
          rate: 0,
        };
        this.$validator.errors.clear();
      }
    },
  },
  created() {
    if (this.getFeedbackModalDetail) {
      this.feedback = {
        message: `Suggesting a missing integration:\n\n- Name:\n- Reason:`,
        rate: 0,
      };
    } else {
      this.$validator.errors.clear();
    }
  },
  methods: {
    ...mapGetters({
      setFeedbackModalDetail: "setFeedbackModalDetail",
    }),
    // send feedback function
    async sendFeedback() {
      if (await this.validateAllFields()) {
        if (this.busy) return;
        this.busy = true;
        this.$store
          .dispatch(SEND_FEEDBACK, this.feedback)
          .then((response) => {
            this.busy = false;
            this.notifyUserMessage({ message: response.message });
            this.$emit("close");
            this.setFeedbackModalDetail(false);
            this.showFeedbackForm = false;
            this.feedback = {
              message: "",
              rate: 0,
            };
          })
          .catch((err) => {
            this.busy = false;
            this.notifyErrorMessage(err.message);
          });
      }
    },
    openSchedule() {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/omar-massad/book-a-demo",
      });
    },
    openSocialLinks(link) {
      window.open(link, "_blank");
    },
    setFeedbackRate(value) {
      this.feedback.rate = value;
    },
  },
};
</script>
