var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "background d-flex justify-center align-center page-loader",
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "mb-4",
    attrs: {
      "alt": "Logo",
      "src": _vm.loaderLogo,
      "width": "150"
    }
  }), _c('br'), _c('v-progress-linear', {
    attrs: {
      "size": "30",
      "indeterminate": "",
      "color": "primary",
      "height": "3"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }