var render = function render(){
  var _vm$info, _vm$info$info;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-14"
  }, [_c('div', [_c('div', [_c('v-row', {
    attrs: {
      "wrap": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|min:3|max:27|name_field_validation',
      expression: "'required|min:3|max:27|name_field_validation'"
    }],
    attrs: {
      "id": "updateTeamName",
      "counter": "30",
      "filled": "",
      "placeholder": "Team name"
    },
    model: {
      value: _vm.update.name,
      callback: function callback($$v) {
        _vm.$set(_vm.update, "name", $$v);
      },
      expression: "update.name"
    }
  }, 'v-text-field', _vm.veeValidate('Name', 'Name'), false))], 1)], 1)], 1), _c('div', {
    staticClass: "mt-4"
  }, [[_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:600',
      expression: "'max:600'"
    }],
    staticClass: "mt-8",
    attrs: {
      "id": "updateTeamDesc",
      "auto-grow": "",
      "counter": "600",
      "filled": "",
      "placeholder": "Description about this team  (Optional)"
    },
    model: {
      value: _vm.update.description,
      callback: function callback($$v) {
        _vm.$set(_vm.update, "description", $$v);
      },
      expression: "update.description"
    }
  }, 'v-textarea', _vm.veeValidate('Description', 'Description'), false))]], 2), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !((_vm$info = _vm.info) !== null && _vm$info !== void 0 && (_vm$info$info = _vm$info.info) !== null && _vm$info$info !== void 0 && _vm$info$info.ownership),
      expression: "!info?.info?.ownership"
    }],
    staticClass: "mt-4"
  }, [[_c('ownership-autocomplete', {
    attrs: {
      "default-value": _vm.ownership
    },
    on: {
      "selected": function selected($event) {
        _vm.update.info.ownership = $event;
      }
    }
  })]], 2)]), _c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "text-transform-none py-7 px-10 my-3",
    attrs: {
      "id": "updateTeamBtn",
      "disabled": !_vm.update.name || _vm.errors.any(),
      "loading": _vm.busy,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": _vm.updateTeam
    }
  }, [_c('span', {
    staticClass: "font14"
  }, [_vm._v(" Save ")])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }